import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import Navbar from "../../Components/Navbar/Navbar";
import Search from "../../Components/Search/Search";
import VMTest from "../../Components/Table/VMTest";
import { message } from "antd";
import { userRequest } from "../../Components/RequestMethod";
import debounce from "lodash.debounce";

const VideoManagement = () => {
  const navigate = useNavigate();

  ///////////////////////////////////////////////////////////applying API to get data

  //     const [vuser ,setVuser] = useState([]);
  //      console.log(vuser)
  //     const fetchVMData = async (searchQuery) => {
  //       // await userRequest.get('/admin/post/getAllPost?status&search=')
  //       await userRequest.get(`/admin/post/getAllPost?status&search=${searchQuery}`)
  //        .then((response) => {
  //          const result = response.data.data;
  //          setVuser(result);
  //        // message.success("data fetched successfully");
  //        })

  //        .catch((err) => {
  //          const errorMessage = err.response?.data?.message || "An error occurred";
  //          message.error(errorMessage);
  //        });
  //    };

  //    useEffect(()=>{
  //       fetchVMData(" ")
  //  },[]);
  //////////////////////////////////////////////////////////to apply fetchData based on status
  const [vuser, setVuser] = useState([]);
  //console.log(vuser);
  const [currentStatus, setCurrentStatus] = useState("");
  const [selectedButton, setSelectedButton] = useState("All");
  //console.log(selectedButton)

  const fetchVMData = async (searchQuery) => {
    // const statusQueryParam = currentStatus ? `&status=${currentStatus}` : '';
    const statusQueryParam = currentStatus ? `${currentStatus}` : "";
    // await userRequest.get(`/admin/post/getAllPost?status&search=${searchQuery}`)
    await userRequest
      .get(
        `/admin/post/getAllPost?&status=${statusQueryParam}&search=${searchQuery}`
      )
      .then((response) => {
        const result = response.data.data;
        setVuser(result);
        // message.success("data fetched successfully");
      })

      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      });
  };

  useEffect(() => {
    fetchVMData(" ");
  }, [currentStatus]);

  const handleRequested = () => {
    setCurrentStatus("Requested");
    //fetchVMData();
    setSelectedButton("requested");
    //window.location.reload();
    //console.log('in requested');
    setCurrentPage(1);
  };

  const handleAccepted = () => {
    setCurrentStatus("Accepted");
    //fetchVMData(searchText);
    setSelectedButton("accepted");
    //console.log('in accepted');
    setCurrentPage(1);
  };

  const handleRejected = () => {
    setCurrentStatus("Rejected");
    //fetchVMData(searchText);
    setSelectedButton("rejected");
    //console.log('in rejected');
    setCurrentPage(1);
  };

  const handlePrivate = () => {
    setCurrentStatus("Private");
    //fetchVMData(searchText);
    setSelectedButton("Private");
    //console.log('in rejected');
    setCurrentPage(1);
  };

  const handleAll = () => {
    setCurrentStatus("");
    //fetchVMData(searchText);
    setSelectedButton("All");
    //console.log('in rejected');
    setCurrentPage(1);
  };
  // const handleButtonSelect = (buttonType) => {
  //   setSelectedButton(buttonType);
  // };

  ///////////////////////////////////////////////////////applying search filter & debounce

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    // fetchData(e.target.value);        before debounce
    debouncedFetchData(searchText);

    if (e.target.value === "") {
      fetchVMData();
      window.location.reload();
      // console.log('ho gya')
    }
  };
  const debouncedFetchData = debounce(fetchVMData, 2000);

  ///////////////////////////////////////////////////////////////applying pagination

  const [currentPage, setCurrentPage] = useState(1);

  const recordsPerPage = 3;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const vuser1 = vuser.slice(firstIndex, lastIndex);
  const npage = vuser ? Math.ceil(vuser.length / recordsPerPage) : 0;

  ////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className={styles.vm_right}>
        <Search searchText={searchText} handleSearch={handleSearch} />{" "}
        {/* Search as component used here  */}
        <div className={styles.header}>
          <div className={styles.header_top}>
            <p className={styles.vm_txt}>Video Management</p>
            <div className={styles.btn}>
              <button onClick={() => navigate("/videoManagement/uploadVideo")}>
                Upload Videos
              </button>
            </div>
          </div>

          <div className={styles.header_bottom}>
            <button
              className={selectedButton === "All" ? styles.req_video : ""}
              onClick={handleAll}
            >
              All Videos
            </button>
            <button
              className={selectedButton === "requested" ? styles.req_video : ""}
              onClick={handleRequested}
            >
              Requested Video
            </button>
            <button
              className={selectedButton === "accepted" ? styles.req_video : ""}
              onClick={handleAccepted}
            >
              Accepted Video
            </button>
            <button
              className={selectedButton === "rejected" ? styles.req_video : ""}
              onClick={handleRejected}
            >
              Rejected Videos
            </button>
            <button
              className={selectedButton === "Private" ? styles.req_video : ""}
              onClick={handlePrivate}
            >
              Private Videos
            </button>
          </div>
        </div>
        <div className={styles.body_main}>
          <p className={styles.vtext1}>Videos</p>
          <p className={styles.vtext2}>Videos</p>
          <div className={styles.white_body}>
            <div className={styles.table}>
              <VMTest
                vuser1={vuser1}
                fetchVMData={fetchVMData}
                currentStatus={currentStatus}
              />
              {/* VMTest Table as component used here*/}
            </div>
            {npage > 1 ? (
              <div className={styles.footer}>
                <div className={styles.userBtn}>
                  <button className={styles.userPrev} onClick={prePage}>
                    Previous
                  </button>
                  <button className={styles.userNext} onClick={nextPage}>
                    Next
                  </button>
                </div>

                <div className={styles.pagination}>
                  {currentPage} of {npage}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
};

export default VideoManagement;
