import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Search from "../../Components/Search/Search";
// import { DatePicker } from "antd";
import DashboardChart from "./DashboardChart";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AiTwotoneCalendar } from 'react-icons/ai'; 

const UserManagement = () => {

  const today = new Date();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date > endDate) {
    setEndDate(date);
   }
  };

  const handleEndDateChange = (date) => {
   setEndDate(date);
  };
 
  return (
    <>
      <div className={styles.dashboardHeader}>
        <Search  />
        <div className={styles.header}>
          <div className={styles.headerLeft}>
          <p>Dashboard</p>
          </div>

          <div className={styles.headerRight}>
            <div className={styles.dateSelectItem}>
              <p>From</p>
              {/* <DatePicker onChange={onFromChange} style={{backgroundColor:"#77e38d", borderRadius:"1.8vw"}}
              disabledDate={disabledFromDate}/> */}
              <div className={styles.custom_datepicker}>
                   <AiTwotoneCalendar className={styles.date_picker_icon} />
                   <DatePicker
                   selected={startDate}
                   onChange={handleStartDateChange}
                  selectsStart
                  maxDate={today} // Set the maximum date to today
                  dateFormat='MMM d,yyyy'
                  className={styles.customDatePickerInput} 
                  />
                 </div>
            </div>
            <div className={styles.dateSelectItem}>
              <p>To</p>
              {/* <DatePicker onChange={onToChange} style={{backgroundColor:"#77e38d",borderRadius:"1.8vw"}}
              disabledDate={disabledToDate}/> */}
              <div className={styles.custom_datepicker}>
                   <AiTwotoneCalendar className={styles.date_picker_icon} />
                <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                minDate={startDate} // Set the minimum date to the selected start date
                maxDate={today} // Set the maximum date to today
                dateFormat='MMM d,yyyy'
                className={styles.customDatePickerInput}
                />
                </div>
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.cardContainer}>
            <div className={styles.singleCard}>
              <img src="Images/card.svg" />
              <div className={styles.cardContent}>
                <div className={styles.cardContentLeft}>
                  <p>Total No. of</p>
                  <p className={styles.cardTextColor}>Screen & Engagement</p>
                </div>
                <div className={styles.cardContentRight}>
                  <p>20,214</p>
                </div>
              </div>
            </div>
            <div className={styles.singleCard}>
              <img src="Images/card.svg" />
              <div className={styles.cardContent}>
                <div className={styles.cardContentLeft}>
                  <p>Total No. of</p>
                  <p className={styles.cardTextColor}>Registered Users</p>
                </div>
                <div className={styles.cardContentRight}>
                  <p>20,214</p>
                </div>
              </div>
            </div>
            <div className={styles.singleCard}>
              <img src="Images/card.svg" />
              <div className={styles.cardContent}>
                <div className={styles.cardContentLeft}>
                  <p>Total No. of</p>
                  <p className={styles.cardTextColor}>Individual Users</p>
                </div>
                <div className={styles.cardContentRight}>
                  <p>20,214</p>
                </div>
              </div>
            </div>
            <div className={styles.singleCard}>
              <img src="Images/card.svg" />
              <div className={styles.cardContent}>
                <div className={styles.cardContentLeft}>
                  <p>Total No. of</p>
                  <p className={styles.cardTextColor}>Deleted Accounts</p>
                </div>
                <div className={styles.cardContentRight}>
                  <p>20,214</p>
                </div>
              </div>
            </div>
          </div>
        <div className={styles.chartContainer}>
          <DashboardChart />
        </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
