import React,{ useEffect, useState,useContext } from 'react'
import styles from "./styles.module.css"
import { useNavigate } from 'react-router-dom'
import logo from '../../Assets/Images/logo.png'
import dashboardg from '../../Assets/Images/dashboardg.png'
import dashboardb from '../../Assets/Images/dashboardb.svg'
import userMgmtg from "../../Assets/Images/user-mgmtg.png"
import userMgmtb from "../../Assets/Images/user-mgmtb.png"
import masterData from "../../Assets/Images/masterdata.png"
import masterDatab from "../../Assets/Images/masterdatab.svg"
import videoMgmt from "../../Assets/Images/video-mgmtg.png"
import videoMgmtb from "../../Assets/Images/video-squareb.svg"
import pushNotifg from "../../Assets/Images/push-notifg.png"
import pushNotifb from "../../Assets/Images/push-notifb.png"
import block from "../../Assets/Images/block.png"
import blockb from "../../Assets/Images/blockb.svg"
import logout from "../../Assets/Images/logout.png"
import { publicRequest } from '../RequestMethod'
import { MyContext } from '../../myContext'

const Navbar = () => {
    const [selectedButton, setSelectedButton] = useState('');
    const {showAddCategoryModal,setShowAddCategoryModal} = useContext(MyContext);
    
//     useEffect(()=>{
//         if(window.location.pathname === '/dashboard'){
//             setSelectedButton('/dashboard')
//         }
//         else if(window.location.pathname === '/userManagement'){
//             setSelectedButton('/userManagement')
//         }
//         else if(window.location.pathname === '/masterData'){
//             setSelectedButton('/masterData')
//         }
//         else if(window.location.pathname === '/videoManagement'){
//             setSelectedButton('/videoManagement')
//         }
//         else if(window.location.pathname === '/pushNotif'){
//             setSelectedButton('/pushNotif')
//         }
//         else if(window.location.pathname === '/blockReport'){
//             setSelectedButton('/blockReport')
//         }  
//    },[]);

    useEffect(() => {
        // Initialize the selected button based on the initial route
         setSelectedButton(getSelectedButton());

        // Listen for changes in browser history
        window.addEventListener('popstate', handleHistoryChange);

        return () => {
        // Clean up the event listener when the component unmounts
        window.removeEventListener('popstate', handleHistoryChange);
        };
    }, []);

    const handleHistoryChange = () => {
        setSelectedButton(getSelectedButton());
    };

    const getSelectedButton = () => {
        const path = window.location.pathname;
        if (path === '/dashboard') return '/dashboard';
        if (path === '/userManagement') return '/userManagement';
        if (path === '/masterData') return '/masterData';
        if (path === '/videoManagement') return '/videoManagement';
        if (path === '/pushNotif') return '/pushNotif';
        if (path === '/blockReport') return '/blockReport';
        return '';
    };
    

    const navigate = useNavigate();

    const handleButtonClick =(route)=>{
        // console.log('hhhhhhhhhhhhhh',route)
        navigate(route);
        setSelectedButton(route);
    }

    
    const handleLogout = async () => {
        try {
          const userToken = JSON.parse(localStorage.getItem("token"));
          //console.log(userToken)
        //   if (userToken) {
        //     await publicRequest.post("/admin/auth/logout", { token: userToken });
        //   }
          //localStorage.removeItem("token");
          localStorage.clear();
          navigate('/');
          window.location.reload()
        } catch (err) {
          console.error('Logout failed:', err);
        }
      };
        
         // Check if the token has expired part of login page
      useEffect(() => {
       
        const tokenExpiration = localStorage.getItem('tokenExpiration');
        if (tokenExpiration) {
           const currentTime = new Date().getTime();
          if (currentTime > Number(tokenExpiration)) {
            // Token has expired, remove it from local storage and state
            localStorage.removeItem('token');
            localStorage.removeItem('tokenExpiration');
          }
        }
      }, []);
    
       
    
    
  return (
    <>
    <div className={showAddCategoryModal ? styles.blur_navbar : styles.navbar}>
            <div className={styles.user_logo}>
            <img src={logo} alt='missing'></img>
            </div>

            <div className={styles.user_icons}>
            <div className={selectedButton === '/dashboard' ? styles.user_dashSelected : styles.user_dash}
            onClick={()=>handleButtonClick('/dashboard')}>
            {selectedButton === '/dashboard' ? <img src={dashboardb} alt='missing'/> : <img src={dashboardg} alt='missing'/>}
                <p >Dashboard</p>
            </div>

            <div className={selectedButton === '/userManagement' ? styles.user_mngmtSelected : styles.user_mngmt }
            onClick={()=>handleButtonClick('/userManagement')}>
            {selectedButton === '/userManagement' ? <img src={userMgmtb} alt='missing'/> : <img src={userMgmtg} alt='missing'/>}
                <p >User Management</p>
            </div>

            <div className={selectedButton === '/masterData' ? styles.master_dataSelected : styles.master_data }
            onClick={()=>handleButtonClick('/masterData')}>
            {selectedButton === '/masterData' ? <img src={masterDatab} alt='missing'/> : <img src={masterData} alt='missing'/>}
                {/* <img src={masterData} alt='missing'></img> */}
                <p >Master Data</p>
            </div>

            <div className={selectedButton === '/videoManagement' ? styles.video_mngmtSelected : styles.video_mngmt }
            onClick={()=>handleButtonClick('/videoManagement')}>
            {selectedButton === '/videoManagement' ? <img src={videoMgmtb} alt='missing'/> : <img src={videoMgmt} alt='missing'/>}
                {/* <img src={videoMgmt} alt='missing'></img> */}
                <p >Video Management</p>
            </div>

            <div className={selectedButton === '/pushNotif' ? styles.push_notifSelected : styles.push_notif }
            onClick={()=>handleButtonClick('/pushNotif')}>
            {selectedButton === '/pushNotif' ? <img src={pushNotifb} alt='missing'/> : <img src={pushNotifg} alt='missing'/>}
                <p >Push Notifications</p>
            </div>

            <div className={selectedButton === '/blockReport' ? styles.block_reportSelected : styles.block_report}
            onClick={()=>handleButtonClick('/blockReport')}>
            {selectedButton === '/blockReport' ? <img src={blockb} alt='missing'/> : <img src={block} alt='missing'/>}
                {/* <img src={block} alt='missing'></img> */}
                <p >Block & Report</p>
            </div>
            </div>

            <div className={styles.logout} onClick={handleLogout}>
                <img src={logout} alt='missing' ></img>
                <p > Log out</p>
            </div>


        </div>
    </>
  )
}

export default Navbar