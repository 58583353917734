import React from 'react'
import styles from './styles.module.css'

const BlockViewDetail = ({viewDetailData,setShowBlockViewDetailModal}) => {
    //console.log(viewDetailData)
    
    const handleClick = ()=>{
        setShowBlockViewDetailModal(false);
    }
    
          

  return (
    <>
    <div className={styles.modal_container}>
        <div className={styles.modal_pic}>
        </div> 
        <div className={styles.row}>
         <p className={styles.modal_label}>Name :</p>
         <p className={styles.modal_text}>{viewDetailData?.blockedByDetails?.fullName}</p>
         </div> 
         <div className={styles.row}>
         <p className={styles.modal_label}>Email :</p>
         <p className={styles.modal_text}>{viewDetailData?.blockedByDetails?.email}</p></div>
         <div className={styles.row}>
         <p className={styles.modal_label}>Blocked by :</p>
         <p className={styles.modal_text}>{viewDetailData?.userDetails?.fullName}</p></div>
          
          <div className={styles.btn}>
            <button id={styles.cancel} onClick={()=>handleClick()}>Close</button>
            
          </div>
    </div>
    </>
  )
}

export default BlockViewDetail