import React,{ useState } from 'react'
import { Table, Checkbox } from 'antd';
import { Link } from 'react-router-dom'
import EditNotification from '../NotificationModal/EditNotificationModal/EditNotification';
import NotificationDetail from '../NotificationModal/NotificationDetailModal/NotificationDetail';
import CustomToggleSwitch from '../ToggleButton/CustomToggleSwitch';


const PushNotifTest = ({ notifData1 }) => {
  //console.log(notifData1);

  /////////////////////////////////////////////////////////////handling edit Notification modal

  const [showEditNotificationModal, setShowEditNotificationModal] = useState(false);
  const [editNotificationData, setEditNotiificationData] = useState('');

  const handleEditNotification =(notifData)=>{
      setShowEditNotificationModal(!showEditNotificationModal);
      setEditNotiificationData(notifData);
   }
  ////////////////////////////////////////////////////////////handling Notification detail modal

  const [showNotificationDetailModal, setShowNotificationDetailModal] = useState(false);
  const [notificationDetail, setNotificationDetail] = useState('');
  const [fdate,setFDate] = useState('');

  const handleNotificationDetail =(notifData,formattedDate)=>{
    setShowNotificationDetailModal(!showNotificationDetailModal);
    setNotificationDetail(notifData);
    setFDate(formattedDate);
 }
/////////////////////////////////////////////////////////////////////////////////////

    const columns = [

        { title: 'Title', dataIndex: 'column1', key: 'column1' },
        { title: 'Time stamp', dataIndex: 'column2', key: 'column2' },
        { title: 'Message', dataIndex: 'column3', key: 'column3' },
        { title: 'Sent to', dataIndex: 'column4', key: 'column4' },
        { title: <div style={{ textAlign: 'center' }}>Actions</div>, dataIndex: 'column5', key: 'column5' },
        { title: <div style={{ textAlign: 'center' }}>Status</div>, dataIndex: 'column6', key: 'column6' },
        
      ];

      const data = notifData1?.length > 0 ? (
        notifData1.map((notifData) => {

          const createdAtDate = new Date(notifData.date);
          const year = createdAtDate.getFullYear();
          const month = createdAtDate.getMonth() + 1; // Month is 0-indexed, so add 1
          const day = createdAtDate.getDate();
          //const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
          const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
          
        return {
        key: notifData._id,
        column1: <div style={{width:"4rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{notifData.title}</div>,
        column2: <div style={{width:"8rem",fontSize:"0.75rem"}}>{formattedDate}  {notifData.time}</div>,
        column3: <div style={{width:"9rem",background:"",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{notifData.message}</div>, // Ensure 'userData.email' exists before accessing
        column4: <div style={{width:"6rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{notifData?.users[0]?._id ? notifData?.users[0]?._id : 'All Users'}</div>,
        column5: (<div style={{display:"flex",justifyContent:"center",gap:"0.5rem",lineHeight:"0.8rem",fontSize:"0.75rem"}}>
        <Link to='' style={{color:"#0C1C36",borderBottom:"1px solid #0C1C36"}}
         onClick={()=>handleEditNotification(notifData)}>Edit</Link>
        <Link to='' style={{color:"#77e38d",borderBottom:"1px solid #77e38d"}}
         onClick={()=>handleNotificationDetail(notifData,formattedDate)}>View Detail</Link> 
        </div>),
        column6: (<div style={{display:"flex",justifyContent:"center"}}><CustomToggleSwitch  /></div>),
          }
          
     })
     ) : (           // If 'user' is empty, return an empty array to avoid errors
       []
     )

  return (
    <>
    {/* <button onClick={()=>handleEditNotification()}>Edit Notification</button>
    <button onClick={()=>handleNotificationDetail()}>Notification Detail</button> */}
     <Table columns={columns}  pagination={false} dataSource={data}/>
     { showEditNotificationModal && <EditNotification setShowEditNotificationModal={setShowEditNotificationModal}
     editNotificationData={editNotificationData} />}
     { showNotificationDetailModal && <NotificationDetail setShowNotificationDetailModal={setShowNotificationDetailModal}
       notificationDetail={notificationDetail} fdate={fdate}/>}
    </>
  )
}

export default PushNotifTest