import React,{ useState,useEffect } from 'react';
import styles from './styles.module.css';
import { userRequest } from '../RequestMethod';
import { message } from 'antd';

const EditUserModal = ({setShowEditUserModal,editingUserData,fetchData}) => {
    

  const [firstName,setFirstName] = useState(editingUserData?.fullName || "");
  const [firstNameTouched,setFirstNameTouched] = useState(false);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName,setLastName] = useState(editingUserData?.lastName || "");
  const [lastNameTouched,setLastNameTouched] = useState(false);
  const [lastNameError, setLastNameError] = useState('');
  const [phone,setPhone] = useState(editingUserData?.phoneNumber || "");
  const [phoneError, setPhoneError] = useState('');
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [email,setEmail] = useState(editingUserData.email || "");
  const [emailError, setEmailError] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  
  //const firstNameRegex = /^\w{2,50}(?: )?$/;
  const firstNameRegex = /^[A-Za-z]{2,50}( [A-Za-z]{2,50}){0,0}$/;
  const nameRegex = /^[A-Za-z]{2,50}( [A-Za-z]{2,50}){0,2}$/;
  const phoneRegex = /^\d{10}$/; // Assuming you expect a 10-digit phone number
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    if (firstNameTouched){
      if(!firstName.trim()){
        setFirstNameError('Please enter your name');
      }
    else if(!nameRegex.test(firstName)) {
      setFirstNameError('Enter name(with 2-50 char.)');
    } else {
      setFirstNameError('');
    }}

    if (lastNameTouched){
      if(!lastName.trim()){
        setLastNameError('Please enter your last name');
      }
    else if(!nameRegex.test(lastName)) {
      setLastNameError('Enter correct last name(with 2-50 char.)without space');
    } else {
      setLastNameError('');
    }}


  if (phoneTouched){
    if(!phone.trim()){
      setPhoneError('Please enter your phone number');
    }
  else if(!phoneRegex.test(phone)) {
    setPhoneError('Invalid phone number');
  } else {
    setPhoneError('');
  }}

  if(emailTouched ){
    if(!email.trim()){
      setEmailError('Please enter your e-mail');
    }
  else if(!emailRegex.test(email)) {
    setEmailError('Invalid email address');
  } else {
    setEmailError('');
  }}
 }, [phone, email,phoneTouched,emailTouched,firstName,firstNameTouched,lastName,lastNameTouched]);

 //////////////////////////////////////////////////////////////////////////////////////////////////
  
  let newData = JSON.stringify({
    "userId": editingUserData._id,
    "fullName": firstName,
    "lastName": lastName,
    "phoneNumber": phone,
    "email": email
  });

  
  const updateUser = async(e) => {

  setFirstNameTouched(true);
  setLastNameTouched(true);
  setPhoneTouched(true);
  setEmailTouched(true);

  if(!firstName.trim()){
    message.error('Please enter your first name');
  }
  else if(!nameRegex.test(firstName)){
    message.error('Enter name(with 2-50 char.) without space at last');
  }
  else if(!lastName.trim()){
    message.error('Please enter your last name');
  }
  else if(!nameRegex.test(lastName)){
    message.error('Enter correct last name(with 2-50 char.)without space');
  }
  else if(!phone.trim()){
    message.error('Please enter your phone number');
  }
  else if(!phoneRegex.test(phone)){
    message.error('Please enter a valid phone number');
  }
  else if(!email.trim()){
    message.error('Please enter your email');
  }
  else if(!emailRegex.test(email)){
    message.error('Please enter a valid email');
  }
  else{
 
    await userRequest.put("/admin/customer/updateUser",newData)
    .then(()=>{
        message.success("User updated successfully");
        setShowEditUserModal(false);
        // fetchData();
        window.location.reload();
    })
    .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
    })
  }}
  

  return (
    <>
     <div className={styles.addUser_right}>
      
      <div className={styles.header}>
        <div className={styles.header_left}>
        <p id={styles.header_t1}>User Management &gt; Edit User</p>
        <p id={styles.header_t2}>Add the following details to create user</p>
        </div>

        <div className={styles.header_right}>
          <button id={styles.btn_cancel} onClick={() => setShowEditUserModal(false)}>Cancel</button>
          <button id={styles.btn_done} onClick={() => updateUser()}>Update</button>
        </div>
      </div>
      
      <div className={styles.form_main}>
      <div className={styles.form_body}> 

        <div className={styles.form_row1}>
        <div className={styles.form_element}>
          <label className={styles.form_label}>Name</label>
          <input type='text' className={styles.form_input} value={firstName} 
          onChange={(e)=>setFirstName(e.target.value)} onBlur={()=>setFirstNameTouched(true)}></input>
          {firstNameError &&(
                  <span className={styles.error_message}>{firstNameError}</span>
                )}
        </div>

        {/* <div className={styles.form_element}>
          <label className={styles.form_label}>Last Name</label>
          <input type='text' className={styles.form_input} value={lastName} 
          onChange={(e)=>setLastName(e.target.value)} onBlur={()=>setLastNameTouched(true)}></input>
          { lastNameError &&(
                  <span className={styles.error_message}>{lastNameError}</span>
                )}
        </div> */}

        <div className={styles.form_element}>
          <label className={styles.form_label}>Phone Number</label>
          <input type='text' className={styles.form_input} value={phone}
          onChange={(e)=>setPhone(e.target.value)} onBlur={()=>setPhoneTouched(true)}></input>
          {phoneError && phoneTouched && <span className={styles.error_message}>{phoneError}</span>}
        </div>
        </div>

        <div className={styles.form_row2}>
        <div className={styles.form_element}>
          <label className={styles.form_label}>E-Mail</label>
          <input type='email' className={styles.form_input} value={email}
          onChange={(e)=>setEmail(e.target.value)} onBlur={()=>setEmailTouched(true)}></input>
          {emailError && emailTouched && <span className={styles.error_message}>{emailError}</span>}
        </div>
        </div>

      </div>
      </div>
      

     </div>

    </>
  )
}

export default EditUserModal