import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import styles from "./styles.module.css";
import Select from "react-select";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },    
  },
  scales: {
    y: {
      grid: {
        display: false, // This line removes the horizontal grid lines
      },
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const getRandomNumber = (min, max) => Math.random() * (max - min) + min;

const data = {
  labels: labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => getRandomNumber(0, 2000)),
      borderColor: "#77e38d",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      lineTension: 0.4,
      borderWidth: 2,
    },
    {
      label: "Dataset 2",
      data: labels.map(() => getRandomNumber(0, 2000)),
      borderColor: "#efc991",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      lineTension: 0.4,
      borderWidth: 2,
    },
  ],
};

function DashboardChart() {
  const [selectedEarning, setSelectedEarning] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const handleEarningChange = (value) => {
    setSelectedEarning(value);
    //console.log(value);
  };

  const handleYearChange = (value) => {
    setSelectedYear(value);
    //console.log(value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "9vw",
      minHeight: "10px",
      height: "2.5vw",
      borderRadius: "12px",
      border: "none",
      boxShadow: "none",
      fontSize: "1vw",
      backgroundColor: "#77e38d",
      fontWeight: "500",
      paddingLeft: "2%",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0",
      backgroundColor: "#e5e5e5",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "30px",
      backgroundColor: "#e5e5e5",
      color: "black",
      cursor: "pointer",
      borderBottom: "1px dashed black",
      textAlign: "center",
      fontSize: "1vw",
      fontWeight: "500",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black", // Set the color to black for the dropdown indicator
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "0.8vw",  // Adjust the font size of the placeholder
      fontWeight: "700", // Adjust the font weight of the placeholder
      color : "black"
    }),
  };

  const earningOption = [
    {
      value: "By Credit Card",
      label: "By Credit Card",
    },
    {
      value: "By Credit Card",
      label: "By Credit Card",
    },
    {
      value: "By UPI",
      label: "By UPI",
    },
  ];

  const yearOption = [
    {
      value: "2023",
      label: "2023",
    },
    {
      value: "2022",
      label: "2022",
    },
    {
      value: "2021",
      label: "2021",
    },
  ];

  return (
    <div className={styles.lineChartContainer}>
      <div className={styles.chartSelectContainer}>
        <Select
          options={earningOption}
          styles={customStyles}
          value={selectedEarning}
          onChange={handleEarningChange}
          placeholder="Earning Via"
          // placeholder={<span style={{ fontWeight: 'bold',color: 'black' }}>Earning Via</span>}
        />
        <Select
          options={yearOption}
          styles={customStyles}
          value={selectedYear}
          onChange={handleYearChange}
          // placeholder="This Year"
          placeholder={<span style={{ fontWeight: 'bold',color: 'black' }}>This Year</span>}
          defaultValue={"By Credit Card"}
        />
      </div>
      {/* <img src="Images/activeUserCard.svg" /> */}
      <div className={styles.activeUser}>
      <img src="Images/dottedIcon.svg" style={{height:"20px",width:"20px",marginLeft:"9rem"}}/>
        <text>Total No. of Active Users</text>
        <p>15,09,000</p>
        <div style={{display:"flex",justifyContent:"flex-end",fontSize:"0.75rem",marginTop:"-0.95rem",marginRight:"0.4rem"}}>
        <img src="Images/icon.svg" style={{height:"15px",width:"15px"}}/>
        <p style={{color:"#1AD598",marginTop:"-0.20rem"}}>+16,24%</p>
        </div>
      </div>
      <Line
        options={options}
        data={data}
        style={{ width: "100%", padding: "1vw 0 0.5vw 0" }}
        height="75vw"
        placeholder={yearOption[0].value}
      />
    </div>
  );
}

export default DashboardChart;
