import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import "./UploadVideoModal.css";
import { useNavigate } from "react-router-dom";
import Search from "../../Components/Search/Search";
import UploadVido from "../../Components/Upload/UploadVido";
import UploadThumbnail from "../../Components/Upload/UploadThumbnail";
import DescriptionBox from "../../Components/DescriptionBox/Descriptionbox";
import RadioButton from "../../Components/RadioButton/RadioButton";
import { userRequest } from "../../Components/RequestMethod";
import { message } from "antd";
import {
  Container,
  Dropdown,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { FaCog } from "react-icons/fa";

const UploadVideoModal = ({
  uploadVideoData,
  setShowUploadVideoModal,
  fetchSingleUserData,
  fetchData,
  storedId,
  fullNameStored,
  userNameStored,
}) => {
  //console.log(uploadVideoData)
  const navigate = useNavigate();
  const [videoURL, setVideoURL] = useState("");
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [description, setDescription] = useState("");
  const [place, setPlace] = useState("");
  const [category, setCategory] = useState("");
  const [tag, setTag] = useState("");
  const [selectedValue, setSelectedValue] = useState("true"); //for radio button on private video & public video
  const [allVideosUrlData, setAllVideosUrlData] = useState([]);
  const [videoType, setVideoType] = useState("");
  const [selectedOption, setSelectedOption] = useState(null); // selected option from video list

  //console.log(category)
  //console.log(videoURL);

  let data = JSON.stringify({
    userId: uploadVideoData?._id ? uploadVideoData?._id : storedId,
    videoUrl: videoURL,
    url: thumbnailURL,
    description: description,
    location: place,
    categoryId: category,
    tags: tag,
    isPublic: "true",
    status: selectedValue === "true" ? "Requested" : "Private",
  });

  const publish = async (e) => {
    if (!videoURL) {
      message.error("Please upload a video before publishing.");
      return; // Stop execution
    }
    if (!thumbnailURL) {
      message.error("Please upload a thumbnail before publishing.");
      return; // Stop execution
    }
    if (!category) {
      message.error("Please select a category before publishing.");
      return; // Stop execution
    }
    if (!description) {
      message.error("Please enter a description before publishing.");
      return; // Stop execution
    }

    await userRequest
      .put("/admin/post/createOrUpdate", data)
      .then(() => {
        message.success("Post published successfully");
        setShowUploadVideoModal(false);

        //fetchSingleUserData() ? (fetchSingleUserData()) : (window.location.reload())
        // fetchData() ? fetchData() : fetchSingleUserData()
        if (fetchSingleUserData) {
          fetchSingleUserData();
        } else {
          window.location.reload();
          //fetchData();
        }
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      });
  };

  const handleClear = () => {
    setShowUploadVideoModal(false);
  };

  // function to select a video from the video list
  const handleSelect = (option) => {
    let data = JSON.parse(option);
    // console.log(data, "option");
    setSelectedOption(data.value);
    handleVideoSelectedFromUrl(data.value);
  };

  ///////////////////////////////////////////////////////////////Only to fetch category Id
  const [user, setUser] = useState("");

  const fetchCategoryData = async () => {
    await userRequest
      .get("/api/category/getAllCategory?page=1&limit=10&search")
      .then((response) => {
        const result = response.data.data;
        setUser(result);
        // message.success("data fetched successfully");
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      });
  };

  //  function to set link from seleted video
  const handleVideoSelectedFromUrl = (data) => {
    // let data = JSON.parse(e.target.value);
    setVideoURL(data.videoUrl);
    setThumbnailURL(data.url);
  };

  // function to fetch all videos uploaded
  const fetchAllVideosData = async () => {
    await userRequest
      .get(`/admin/post/getAllPost?&status=&search=`)
      .then((response) => {
        const result = response.data.data;

        let resultWithShortDesc = result?.map((item) => {
          let data = item?.description?.split(" ");
          let shortDescription = "";
          if (data?.length > 3) {
            shortDescription = data
              ?.slice(0, 3)
              .toString()
              .replaceAll(",", " ");
          }
          return {
            ...item,
            shortDescription: shortDescription ? shortDescription : "",
          };
        });

        setAllVideosUrlData(resultWithShortDesc);
        // message.success("data fetched successfully");
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      });
  };

  useEffect(() => {
    fetchCategoryData();
    fetchAllVideosData();
  }, []);

  // effect to change video type
  useEffect(() => {
    if (selectedValue === "true") {
      setVideoType("File");
    }
  }, [selectedValue, videoType]);

  useEffect(() => {
    setVideoURL("");
    setThumbnailURL("");
    setSelectedOption("");
  }, [selectedValue]);

  // console.log(selectedValue, "selected value");
  console.log(videoURL, "viedourl");
  console.log(thumbnailURL, "thumb");

  //////////////////////////////////////////////////////////
  const [placeTouched, setPlaceTouched] = useState(false);
  const [placeError, setPlaceError] = useState("");

  //const placeRegex = /^[a-zA-Z0-9]{1,150}$/;
  const placeRegex = /^(?!.*\s\s)[a-zA-Z0-9\s]{1,150}$/;

  useEffect(() => {
    if (placeTouched) {
      if (!place.trim()) {
        setPlaceError("Please enter place ");
      } else if (!placeRegex.test(place)) {
        setPlaceError("Accept only numbers and space .Max char limit 150");
      } else {
        setPlaceError("");
      }
    }
  }, [place, placeTouched]);

  // console
  // console.log(videoType, "videoType");
  // console.log(allVideosUrlData,"allvid")

  /////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className={styles.uploadVideo_right}>
        {/*<Search />                                       Search as component used here  */}
        <div className={styles.header}>
          <div className={styles.header_left}>
            <div className={styles.profPic}>{/* <img src={Ion}></img> */}</div>
            <div className={styles.macintosh}>
              <p className={styles.macintosh1}>
                {uploadVideoData?.fullName
                  ? uploadVideoData?.fullName
                  : fullNameStored}
              </p>
              <p className={styles.macintosh2}>
                @
                {uploadVideoData?.userName
                  ? uploadVideoData?.userName
                  : userNameStored}
              </p>
            </div>
          </div>

          <div className={styles.header_right}>
            <button id={styles.btn_clear} onClick={handleClear}>
              Clear
            </button>
            <button id={styles.btn_publish} onClick={() => publish()}>
              Publish
            </button>
          </div>
        </div>

        <div className={styles.uploadV_main}>
          <div className={styles.uploadV_body} style={{ height: "auto" }}>
            {selectedValue !== "true" ? (
              <div className="radio-group">
                {/*  */}
                <div className={styles.radio_container}>
                  {/* <label className={style.radio_label} > */}
                  {/* <label className={`${style.radio_label} ${selectedValue === "option1" ? style.selected : ""}`}> */}
                  <label
                    className={
                      videoType === "File"
                        ? `${styles.radio_label} ${styles.selected}`
                        : styles.radio_label
                    }
                  >
                    <input
                      type="radio"
                      name="videoType"
                      value={"File"}
                      onChange={(e) => {
                        setVideoType(e.target.value);
                        setVideoURL("");
                        setThumbnailURL("");
                      }}
                    />
                    <div className={styles.radio_button}>
                      <div className={styles.radio_button_inner}></div>
                    </div>
                    Select from device
                  </label>

                  {/* <label className={style.radio_label}> */}
                  <label
                    className={
                      videoType === "URL"
                        ? `${styles.radio_label} ${styles.selected}`
                        : styles.radio_label
                    }
                  >
                    <input
                      type="radio"
                      value={"URL"}
                      name="videoType"
                      // defaultChecked={true}
                      onChange={(e) => {
                        setVideoType(e.target.value);
                        setVideoURL("");
                        setThumbnailURL("");
                      }}
                    />
                    <div className={styles.radio_button}>
                      <div className={styles.radio_button_inner}></div>
                    </div>
                    Select from link
                  </label>
                </div>
                {/*  */}
                {/* <div>
                  <input
                    type="radio"
                    id="File"
                    name="videoType"
                    value={"File"}
                    defaultChecked={true}
                    onChange={(e) => {
                      setVideoType(e.target.value);
                      setVideoURL("");
                      setThumbnailURL("");
                    }}
                  />
                  <label htmlFor="File">Select a file from device</label>
                </div> */}
                {/* <div>
                  <input
                    type="radio"
                    id="Url"
                    name="videoType"
                    value={"URL"}
                    // defaultChecked={true}
                    onChange={(e) => {
                      setVideoType(e.target.value);
                      setVideoURL("");
                      setThumbnailURL("");
                    }}
                  />
                  <label htmlFor="Url">Select from video link</label>
                </div> */}
              </div>
            ) : (
              ""
            )}
            <div className={styles.uploadV_video}>
              {videoType === "File" ? (
                <>
                  <UploadVido videoURL={videoURL} setVideoURL={setVideoURL} />
                  {/*  UploadVideo as component used here  */}
                  <UploadThumbnail
                    thumbnailURL={thumbnailURL}
                    setThumbnailURL={setThumbnailURL}
                  />{" "}
                  {/* UploadThumbnail as component used here  */}
                </>
              ) : videoType === "URL" ? (
                <div>
                  {/* <select onChange={(e)=>handleVideoSelectedFromUrl(e)}>
          <option>Select a video</option>

          {allVideosUrlData?.map(item=>{
            return(
              <option style={{backgroundImage:item.url}} value={JSON.stringify(item)}>{`${item.videoUrl} (${item.categoryId.categoryName})`}</option>
            )
          })}
          </select> */}
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle
                      style={{ backgroundColor: "#77E38D", border: "none" }}
                      id="dropdown-basic"
                    >
                      {selectedOption ? (
                        <>
                          <img
                            src={selectedOption.url}
                            alt={selectedOption.label}
                            className={styles.select_image}
                          />
                          <span>
                            {selectedOption?.shortDescription
                              ? selectedOption?.shortDescription + "..."
                              : selectedOption?.description || "NA"}
                          </span>
                        </>
                      ) : (
                        "Select an option"
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{ height: "60vh", overflowY: "auto" }}
                    >
                      {allVideosUrlData?.map((item) => {
                        return (
                          <Dropdown.Item
                            eventKey={JSON.stringify({ value: item })}
                          >
                            <img
                              style={{ width: "30px", height: "30px" }}
                              src={item.url}
                              alt="Options"
                              className="select-image"
                            />
                            <span>{` ${
                              item?.shortDescription
                                ? item?.shortDescription + "..."
                                : item?.description || "NA"
                            }`}</span>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                ""
              )}
              {/* <UploadVido videoURL={videoURL} setVideoURL={setVideoURL}/>   
      <UploadThumbnail thumbnailURL={thumbnailURL} setThumbnailURL={setThumbnailURL}/>   */}
              <RadioButton
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />{" "}
              {/* Radiobutton as component used here  */}
            </div>
            <DescriptionBox
              description={description}
              setDescription={setDescription}
            />{" "}
            {/* Description as component used here  */}
            <div className={styles.form_row1}>
              <div className={styles.place_w_error}>
                <div className={styles.form_element1}>
                  <label className={styles.form_label}>Places</label>
                  <input
                    type="text"
                    className={styles.form_input}
                    value={place}
                    onChange={(e) => setPlace(e.target.value)}
                    onBlur={() => setPlaceTouched(true)}
                  ></input>
                </div>
                {placeError && (
                  <span className={styles.place_error}>{placeError}</span>
                )}
              </div>

              <div className={styles.form_element}>
                <label className={styles.form_label}>Category</label>
                <select
                  className={styles.form_input}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value=""></option>
                  {user.length > 0
                    ? user.map((userData) => (
                        <option key={userData._id} value={userData._id}>
                          {userData.categoryName}
                        </option>
                      ))
                    : []}
                </select>
              </div>

              <div className={styles.form_element}>
                <label className={styles.form_label}>Tags</label>
                <input
                  type="text"
                  className={styles.form_input}
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadVideoModal;
