import React,{ useState,useEffect } from 'react'
import styles from "./styles.module.css"
import ProfileModal from '../ProfileModal/ProfileModal'
import search from "../../Assets/Images/search.png"
import sort from "../../Assets/Images/sort.png"
import pushnotifig from "../../Assets/Images/pushnotifig.svg"
import userMgmt from "../../Assets/Images/user-mgmtb.png"
import current from "../../Assets/Images/current.svg"
import dropDown from "../../Assets/Images/ddgrey.png"
import question from "../../Assets/Images/question.svg"
import userIcon from "../../Assets/Images/john-doe.svg"
import { userRequest } from '../../Components/RequestMethod';
import { message } from 'antd'


const Search = ({searchText,handleSearch}) => {
  //console.log(admin);

  const [admin, setAdmin] = useState(false);
  const[showProfile,setShowProfile] = useState(false);
 
  const shouldShowSearchInput = window.location.pathname === '/dashboard';

  const droppDown = ()=>{  
    // setShowProfile(true);
    setShowProfile((prevShowProfile) => !prevShowProfile);
  }
   
  const fetchAdmin =async()=>{
    await userRequest.get('/admin/user/getUser')
    .then((response) => {
      const result = response.data;
      setAdmin(result);
      //message.success("data fetched successfully");
    })
    .catch((err) =>{
      const errorMessage = err.response?.data?.message || "An error occurred";
      message.error(errorMessage);
    })
  }
  //console.log(admin?.user?.fullName);

  useEffect(()=>{
    fetchAdmin()
  },[]);


  return (
    <>
    <div className={styles.search_container}>
      <div className={shouldShowSearchInput ? styles.header_bar_hidden : styles.header_bar}>
      
                <img src={search} alt='missing'></img>
                
                <input type='text' className={styles.search_bar} placeholder='search' value={searchText} onChange={handleSearch} ></input>
               
                <img src={sort} alt='missing'></img>
               
            </div>
            
     
     {/* <div className={selectedButton === '/dashboard' ? styles.user_dashSelected : styles.user_dash}></div> */}
    <div className={styles.header_middle}>
      {shouldShowSearchInput ? <img src={question} alt='missing'/> : <img src={question} alt='missing'/>}
    
    <img src={pushnotifig} alt='missing'></img>
    {/* <img src={current} alt='missing' height='40' width='30'></img> */}
    </div>

    <div className={styles.header_right}>
    {admin?.user?.profilePic ? <img src={admin?.user?.profilePic} id={styles.pic_left}></img> : <img src={userIcon}></img>}
      {/* <img src={userIcon}></img> */}
      {/* <img src={admin?.user?.profilePic}></img> */}
      <p>John Doe</p>
      <img src={dropDown} id={styles.pic_right} onClick={()=>droppDown()}></img>
      </div>        
        
      { showProfile && <ProfileModal setShowProfile={setShowProfile}/>}   

    </div>
    </>
  )
}

export default Search