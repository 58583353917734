import React,{ useState,useEffect } from 'react'
import styles from './styles.module.css'
import UploadProfile from '../Upload/UploadProfile/UploadProfile'
import { userRequest } from '../RequestMethod';
import { message } from 'antd';

const EditProfileModal = ({setShowEditProfileModal,editingAdminData}) => {
    console.log(editingAdminData);

    const [profileURL, setProfileURL] = useState(editingAdminData?.user?.profilePic || '');
    
    const [firstName,setFirstName] = useState(editingAdminData?.user?.fullName || '');
    
    const [lastName,setLastName] = useState(editingAdminData?.user?.lastName || '');
    
    const [phone,setPhone] = useState(editingAdminData?.user?.phoneNumber || '');
    const [email,setEmail] = useState(editingAdminData?.user?.email || '');
    const [homeAddress,setHomeAddress] = useState(editingAdminData?.user?.address || '');

    
    let newData = JSON.stringify({
      "userId": editingAdminData?.user?._id,
      "profilePic": profileURL,
      "fullName": firstName,
      "lastName": lastName,
      "phoneNumber": phone,
      "email": email,
      "address" : homeAddress,
    });

    const updateAdmin = async(e) => {
      setFirstNameTouched(true);
      setLastNameTouched(true);
      setIsTouchedPhone(true);
      setIsTouchedEmail(true);

      if (emailError || phoneError) {
        return; // Don't proceed with the update
      }
      else if (!firstName.trim() || firstNameError || !lastName.trim() || lastNameError ){
        return;
      }

      else{

      await userRequest.put("/admin/user/updateUser",newData)
      .then(()=>{
          message.success("User updated successfully");
          setShowEditProfileModal(false);
          // fetchData();
          window.location.reload();
      })
      .catch((err) => {
          const errorMessage = err.response?.data?.message || "An error occurred";
          message.error(errorMessage);
      })
    }
    }

    const handleCancel = () =>{
        setShowEditProfileModal(false);
    }
    ///////////////////////////////////////////////////////////////////////Validation part

    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isTouchedEmail, setIsTouchedEmail] = useState(false);
    const [isTouchedPhone, setIsTouchedPhone] = useState(false);

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [firstNameTouched,setFirstNameTouched] = useState(false);
    const [lastNameTouched,setLastNameTouched] = useState(false);

    const nameRegex = /^[A-Za-z]{2,50}( [A-Za-z]{2,50}){0,1}$/;
    const phoneRegex = /^\d{10}$/; // Assuming you expect a 10-digit phone number
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    

    useEffect(() => {
      if (firstNameTouched){
        if(!firstName.trim()){
          setFirstNameError('Please enter your name');
        }
      else if(!nameRegex.test(firstName)) {
        setFirstNameError('Enter correct name(with 2-50 char.)');
      } else {
        setFirstNameError('');
      }}

      if (lastNameTouched){
        if(!lastName.trim()){
          setLastNameError('Please enter your last name');
        }
      else if(!nameRegex.test(lastName)) {
        setLastNameError('Enter correct last name(with 2-50 char.)');
      } else {
        setLastNameError('');
      }}

      if (isTouchedEmail) {
        if (!email.trim()) {
          setEmailError('Please enter your email.');
        }else if (!emailRegex.test(email)) {
        setEmailError('Invalid email address');
      } else {
        setEmailError('');
      }}

      if (isTouchedPhone) {
        if (!phone.trim()) {
          setPhoneError('Please enter your phone number.');
        }else if (!phoneRegex.test(phone)) {
        setPhoneError('Phone number should be of 10 digits.');
      } else {
        setPhoneError('');
      }}
    },[firstName,firstNameTouched,lastName,lastNameTouched,email,phone,isTouchedEmail,isTouchedPhone]);

    

  return (
    <>
    <div className={styles.mp_right}>
        {/* <Search /> */}

    <div className={styles.header}>
              <div className={styles.header_right}>
              <button id={styles.btn_cancel} onClick={()=>handleCancel()}>Cancel</button>
              <button id={styles.btn_save} onClick={()=>updateAdmin()}>Save</button>
            
              </div>
        </div>
        

        <div className={styles.profile_main}>
          <div className={styles.profile_body}> 

          <div className={styles.personal_info}>
          <div className={styles.text}>Personal Info</div>
          <div className={styles.row1}>
          <div className={styles.firstName}>
            <label className={styles.label}>First Name</label>
            <input type='text' className={styles.inputtext} value={firstName} 
            onChange={(e)=>setFirstName(e.target.value)}
            onBlur={() => setFirstNameTouched(true)}></input>
            {firstNameError && <div className={styles.error}>{firstNameError}</div>}
          </div>
          <div className={styles.lastName}>
            <label className={styles.label}>Last Name</label>
            <input type='text' className={styles.inputtext} value={lastName} 
            onChange={(e)=>setLastName(e.target.value)}
            onBlur={() => setLastNameTouched(true)}></input>
            {lastNameError && <div className={styles.error}>{lastNameError}</div>}
          </div>
          </div>

          <div className={styles.row2}>
          <div className={styles.phoneNumber}>
            <label className={styles.label}>Phone Number</label>
            <input type='number' className={styles.inputtext} value={phone} 
            onChange={(e)=>setPhone(e.target.value)}
            onBlur={() => setIsTouchedPhone(true)} ></input>
            {phoneError && <div className={styles.error}>{phoneError}</div>}
          </div>
          
          <div className={styles.email}>
            <label className={styles.label}>E-Mail</label>
            <input type='email' className={styles.inputtext} value={email} 
            onChange={(e)=>setEmail(e.target.value)}
            onBlur={() => setIsTouchedEmail(true)}></input>
            {emailError && <div className={styles.error}>{emailError}</div>}
          </div>
          </div>
          
          <div className={styles.row3}>
            <label className={styles.label}>Home Address</label>
            <input className={styles.inputtext} value={homeAddress}
            onChange={(e)=>setHomeAddress(e.target.value)}></input>
          </div>
          </div>
          
          <div className={styles.gap}></div>
          <div className={styles.addProfile}>
          <div className={styles.text}>Add profile</div>
          <div className={styles.upload_profile}>
          <UploadProfile profileURL={profileURL} setProfileURL={setProfileURL}/> 
          </div>
          </div>
      
      </div>
      </div>

    </div>
    </>
  )
}

export default EditProfileModal