import React from 'react'
import styles from './styles.module.css'

const ReportViewDetail = ({viewDetailData,setShowReportViewDetailModal}) => {
    //console.log(viewDetailData)
    
    const handleClick = ()=>{
        setShowReportViewDetailModal(false);
    }

          const createdAtDate = new Date(viewDetailData?.userDetails?.updatedAt);
          const year = createdAtDate.getFullYear();
          const month = createdAtDate.getMonth() + 1; // Month is 0-indexed, so add 1
          const day = createdAtDate.getDate();
          const formattedCreatedAt = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

  return (
    <>
    <div className={styles.modal_container}>
        <div className={styles.modal_pic}>
        </div>  
        <div className={styles.row}>
         <p className={styles.modal_label}>Name :</p>
         <p className={styles.modal_text}>
          {viewDetailData?.postId?._id ? viewDetailData?.userDetails?.fullName : viewDetailData?.blockedByDetails?.fullName}</p></div>
         <div className={styles.row}>
         <p className={styles.modal_label}>E-mail :</p>
         <p className={styles.modal_text}>
          {viewDetailData?.postId?._id ? viewDetailData?.userDetails?.email : viewDetailData?.blockedByDetails?.email}</p></div>
         <div className={styles.row}>
         <p className={styles.modal_label}>Category :</p>
         <p className={styles.modal_text}>{viewDetailData?.category}</p></div>
         <div className={styles.row}>
         <p className={styles.modal_label}>Reported on :</p>
         <p className={styles.modal_text}>{formattedCreatedAt}</p></div>
          
          <div className={styles.btn}>
            <button id={styles.cancel} onClick={()=>handleClick()}>Close</button>
            
          </div>
    </div>
    </>
  )
}

export default ReportViewDetail