import React,{ useState,useEffect,useContext } from 'react'
import styles from "./styles.module.css"
import Search from '../../Components/Search/Search'
import MasterDataTest from '../../Components/Table/MasterDataTest'
import Modal from '../../Components/Modal/Modal'
import { userRequest } from '../../Components/RequestMethod';
import { message } from "antd";
import debounce from 'lodash.debounce';
import { MyContext } from '../../myContext'

const MasterData = () => {

    const [user ,setUser] = useState([]);

    const {showAddCategoryModal,setShowAddCategoryModal} = useContext(MyContext);

    const [currentPage ,setCurrentPage] = useState(1);
    const recordsPerPage = 4;
    const lastIndex = currentPage*recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
  
    const user1 = user.slice(firstIndex, lastIndex);
    const npage = user? Math.ceil(user.length/recordsPerPage) : 0;
    // const numbers = [...Array(npage + 1).key()].slice(1)

    
    const fetchMasterData = async (searchQuery) => {
      // await userRequest.get("/api/category/getAllCategory?page=1&limit=10&search")
      await userRequest.get(`/api/category/getAllCategory?page=1&limit=10&search=${searchQuery ??''}`)
        .then((response) => {
          const result = response.data.data;
          setUser(result);
        // message.success("data fetched successfully");
        })
        .catch((err) => {
          const errorMessage = err.response?.data?.message || "An error occurred";
          message.error(errorMessage);
        });
    };
  
    useEffect(()=>{
      fetchMasterData()
  },[]);
   ///////////////////////////////////////////////////////applying search filter & debounce

const [searchText, setSearchText] = useState('');

const handleSearch = (e) => {
  setSearchText(e.target.value);
  // fetchData(e.target.value);        before debounce 
  debouncedFetchData(searchText);  
  
  if(e.target.value === ''){
    fetchMasterData();
    //window.location.reload();
    // console.log('ho gya')
  }
};
const debouncedFetchData = debounce(fetchMasterData, 2000);
////////////////////////////////////////////////////////////////////////////////////////////////
   
    const addCategory = ()=>{
        //localStorage.setItem("showAddCategoryModal",showAddCategoryModal);
        //window.location.reload();
        setShowAddCategoryModal(true)
      }
  /////////////////////////////////////////////////////////////////////////////////////
  

  return (
    <>                  
     <div className={showAddCategoryModal ? styles.masterData_rightblur : styles.masterData_right}>
      <Search searchText={searchText} handleSearch={handleSearch}/>                   {/* Search as component used here  */}
      <div className={styles.header}>
              <div className={styles.header_left}>
              Master Data 
              <p>Category</p>
              </div>

              <div className={styles.header_right}>
                <button  onClick={()=>addCategory()}>+ Add Category</button>
              </div>
            </div>


            <div className={styles.body}>
                <p>Videos</p>                     {/* Videos is hidden here  */}  
                <div className={styles.white_body}>
                    <div className={styles.table}>
                      <MasterDataTest  user1={user1} showAddCategoryModal={showAddCategoryModal}
                      fetchMasterData={fetchMasterData}/>        {/*   Table as component used here */}
                    </div>   
                </div>
                                                        {/* pages on condition */}
                {npage > 1 ? (                        
                <div className={styles.footer}>
                <div className={styles.userBtn}>
                  <button className={styles.userPrev} onClick={prePage}>Previous</button>
                  <button className={styles.userNext} onClick={nextPage}>Next</button>
                 </div>

                 <div className={styles.pagination}>{currentPage} of {npage}</div>
                 </div>
                  ) : null
                }

            </div>
     </div>
     
     { showAddCategoryModal && <Modal setShowAddCategoryModal={setShowAddCategoryModal}
            fetchMasterData={fetchMasterData} />} 
    
    </>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1);
    }
  }


  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1);
    }
  }

}

export default MasterData