import React,{ useState } from 'react'
import style from './styles.module.css'

const RadioButtonSend = ({sendSelectedValue, setSendSelectedValue}) => {
    //console.log(sendSelectedValue)
    
    const handleChange = (event) => {
      setSendSelectedValue(event.target.value);
    };
  
  return (
    <>
    <div className={style.radio_container}>
     {/* <label className={selectedValue === "false" ? `${style.radio_label} ${style.selected}` : style.radio_label}> */}
     <label className={style.radio_label}>
        <input
          type="radio"
          value="false"
          checked={sendSelectedValue === "false"}
          onChange={handleChange}
        />
        <div className={style.radio_button}></div>
        All Users
      </label>
      
      
      {/* <label className={style.radio_label}> */}
      <label className={style.radio_label}>
        <input
          type="radio"
          value="true"
          checked={sendSelectedValue === "true"}
          onChange={handleChange}
        />
        <div className={style.radio_button}></div>
        Individual User
      </label>
      </div>
      
    </>
  )
}

export default RadioButtonSend