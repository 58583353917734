import React,{ useState, useEffect } from 'react'
import styles from "./styles.module.css";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { publicRequest, userRequest } from "../../Components/RequestMethod";
import { message } from "antd";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
    const navigate = useNavigate();
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isTouchedEmail, setIsTouchedEmail] = useState(false);
    const [isTouchedPassword, setIsTouchedPassword] = useState(false);
    
    const [emailErrorType, setEmailErrorType] = useState('');
    const [passwordErrorType, setPasswordErrorType] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  
    let data = JSON.stringify({
      "email": email,
      "password": password
    });
   
  // Setting token expiration time 

    const setTokenWithExpiration = (tokenValue) => {
      const expirationTime = new Date().getTime() + 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds
      localStorage.setItem('token', JSON.stringify(tokenValue));
      localStorage.setItem('tokenExpiration', expirationTime);
    };
    
  
    const handleLogin = async (e) => {

      if (!email.trim() || !password.trim()) {
        setEmailError(!email.trim() ? 'Please enter your email.' : '');
        setPasswordError(!password.trim() ? 'Please enter your password.' : '');
        setEmailErrorType('blank');
        setPasswordErrorType('blank');
        //return; // Return early to avoid making the API call
      }else if(!emailError && !passwordError){
       
     await publicRequest.post("/admin/auth/login", data)
        .then((res) => {
          message.success("Logged In successfully");
          // localStorage.setItem("token",JSON.stringify(res.data.token))
          setTokenWithExpiration(res.data.token)
          navigate('/dashboard');
          window.location.reload()
        })
        .catch((err) => {
          const errorMessage = err.response?.data?.message || "Please check your email or password";
          message.error(errorMessage);
           if(err.status == 403){
            localStorage.removeItem("token")
           }
        });
      }
    };

    
    useEffect(() => {
      if (isTouchedEmail) {
        if (!email.trim()) {
          setEmailError('Please enter your email.');
          setEmailErrorType('blank');
        }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        setEmailError('Invalid email address');
        setEmailErrorType('invalid');
      } else {
        setEmailError('');
        setEmailErrorType('');
      }}
      
       if(isTouchedPassword){
        if (!password.trim()) {
          setPasswordError('Please enter your password.');
          setPasswordErrorType('blank');
        }else if (!/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/.test(password)) {
        setPasswordError('Invalid password');
        setPasswordErrorType('invalid');
      } else {
        setPasswordError('');
        setPasswordErrorType('');
      }}else{
        setPasswordError('');
        setPasswordErrorType('');
      }
    }, [email,password,isTouchedEmail,isTouchedPassword]);

  return (
    <>
    <div className={styles.home_main}>
        <div className={styles.home_sub}>
          <div className={styles.welcome_txt}>
            <img src="Images/logo.png"></img>
            <p id={styles.wel_txt1}>Welcome back</p>
            <p className={styles.wel_txt2}>
              Lorem Ipsum is simply dummy text of the
            </p>
            <p className={styles.wel_txt3}>Lorem Ipsum has been the industry's</p>

            <div className={styles.input}>


              <div className={styles.envelope}>
              <img src="Images/envelope.png" style={{width:"0.85rem",marginRight:"1rem",marginBottom:"1rem"}} ></img>
              <input
                type="email"
                className={styles.input1}
                placeholder="Email"
                value={email}
                onChange={(e) => {setEmail(e.target.value);
                  setIsTouchedEmail(true); }}
              ></input>
              </div>
              {emailError && <div className={emailErrorType === 'blank' ? styles.mail_blankerror : styles.mail_error}>
              {emailError}</div>}




              <div className={styles.envelope1}>
              <img src="Images/lock1.png" alt="missing" style={{width:"1rem",marginRight:"1rem",marginBottom:"1rem"}} ></img>
              <input
                type={showPassword ? 'text' : 'password'}
                className={styles.input2}
                placeholder="Enter Password"
                value={password}
                onChange={(e) => {setPassword(e.target.value);
                  setIsTouchedPassword(true); }}
              ></input>
              <div className={styles.PasswordtoggleBtn} onClick={togglePasswordVisibility}>
                  {/* {showPassword ? <FaEyeSlash style={{ color: 'white'}}/> : <FaEye style={{ color: 'white'}}/>} */}
                  {showPassword ? <FaEye style={{ color: 'white'}}/> : <FaEyeSlash style={{ color: 'white'}}/> }
               </div>
              </div>
              {passwordError && <div className={passwordErrorType === 'blank' ? styles.pass_blankerror : styles.pass_error}>{passwordError}</div>}
            </div>



            <p className={styles.forgot_p} onClick={()=>navigate('/forgotPassword')}>Forgot password?</p>

            <div className={styles.btn}>
              <button onClick={handleLogin}>Login</button>
            </div>
          </div>

          <div className={styles.welcome_pic}>
            <p id={styles.welcome_pic_heading}>Welcome to OKOMO</p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod
            </p>
            <p>
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim{" "}
            </p>
            <p>
              veniam, quis nostrudexercitation ullamco laboris nisi ut aliquip
              ex ea{" "}
            </p>
            <p>commodo consequat. Duis aute irure dolor in</p>
          </div>
        </div>
      </div>

    </>
  )
}

export default Login