import React,{ useState,useEffect } from 'react'
import styles from './styles.module.css'
import Search from '../../Components/Search/Search'
import BlockTest from '../../Components/Table/BlockTest'
import ReportTest from '../../Components/Table/ReportTest'
import { message } from "antd";
import { userRequest } from '../../Components/RequestMethod';
import debounce from 'lodash.debounce';


const BlocknReports = () => {

    const [reportedUser ,setReportedUser] = useState([]);
    const [blockedUser ,setBlockedUser] = useState([]);
    //console.log(reportedUser);

  
    ////////////////////////////////////////////////////////////////handling button status reports or block
    // const [currentStatus, setCurrentStatus] = useState(null);
    const [selectedButton, setSelectedButton] = useState('reports');
    //console.log(selectedButton)

    const handleReports = () => {
        //setCurrentStatus('reports');
        setSelectedButton('reports');
        //console.log('in requested');
        setCurrentPage(1);
      };

      const handleBlock = () => {
        //setCurrentStatus('block');
        setSelectedButton('block');
        //console.log('in requested');
        setCurrentPage(1);
      };

    //////////////////////////////////////////////////////////API call for reported user 

    const fetchReportedData = async (searchQuery) => {
         //await userRequest.get("/api/reportOrBlock/getAllReportOrBlock?page=1&limit=10&type=REPORT&userId=")
         await userRequest.get(`/api/reportOrBlock/getAllReportOrBlock?page=1&limit=100&type=REPORT&search=${searchQuery}`)
          .then((response) => {
            const result = response.data.data;
            setReportedUser(result);
          // message.success("data fetched successfully");
          })
          .catch((err) => {
            const errorMessage = err.response?.data?.message || "An error occurred";
            message.error(errorMessage);
          });
      };

    /////////////////////////////////////////////////////////////////API call for blocked user 

      const fetchBlockedData = async (searchQuery) => {
        //await userRequest.get("/api/reportOrBlock/getAllReportOrBlock?page=1&limit=10&type=BLOCK&userId=")
        await userRequest.get(`/api/reportOrBlock/getAllReportOrBlock?page=1&limit=100&type=BLOCK&search=${searchQuery}`)
         .then((response) => {
           const result = response.data.data;
           setBlockedUser(result);
         // message.success("data fetched successfully");
         })
         .catch((err) => {
           const errorMessage = err.response?.data?.message || "An error occurred";
           message.error(errorMessage);
         });
     };
    
      useEffect(()=>{
        fetchReportedData(" ");
        fetchBlockedData(" ");
    },[]);
    ///////////////////////////////////////////////////////applying search filter & debounce

    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        // fetchData(e.target.value);        before debounce 
        debouncedFetchData(searchText);  
  
      if(e.target.value === ''){
        selectedButton === 'block' ? fetchBlockedData() : fetchReportedData();
      //window.location.reload();
      // console.log('ho gya')
      }
    };
    const debouncedFetchData = selectedButton === 'block' ? debounce(fetchBlockedData, 2000) : 
                debounce(fetchReportedData,2000);

      ////////////////////////////////////////////////////////////applying pagination
      const [currentPage, setCurrentPage] = useState(1);
      // const npage = 5;
      const recordsPerPage = 4;
      const lastIndex = currentPage*recordsPerPage;
      const firstIndex = lastIndex - recordsPerPage;
      const reportedUser1 = reportedUser.slice(firstIndex, lastIndex);
      const nrpage = reportedUser? Math.ceil(reportedUser.length/recordsPerPage): 0;
      /////////////////////////////////////////////////////////////////////////////for blocked user
      const blockedUser1 = blockedUser.slice(firstIndex, lastIndex);
      const nbpage = blockedUser? Math.ceil(blockedUser.length/recordsPerPage): 0;
  
      // selectedButton === 'reports' ? (const npage = nrpage) : (const npage = nbpage) 
      const npage = selectedButton === 'block' ? nbpage : nrpage;

  return (
    <>
    <div className={styles.blocknReport_right}>
      {/* <Search searchText={searchText} handleSearch={handleSearch}/> */}
      <Search searchText={searchText} handleSearch={handleSearch} />
      <div className={styles.header}>
              <div className={styles.header_top}>
              Block & reports
              </div>  

              <div className={styles.header_bottom}>
                <button className={selectedButton === 'reports' ? styles.reports : ''}
                onClick={handleReports}>Reports</button>
                <button className={selectedButton === 'block' ? styles.block : ''} 
                onClick={handleBlock}>Block</button>
              </div>
        </div>


            <div className={styles.body}>
                <p>Videos</p>                      {/* Videos is hidden here  */}  
                <div className={styles.white_body}>
                    <div className={styles.table}>
                      {selectedButton == 'reports' ? 
                      <ReportTest reportedUser1 = {reportedUser1} fetchReportedData={fetchReportedData}/> 
                      :
                      <BlockTest blockedUser1 = {blockedUser1}/>
                      }   
                    </div>   
                </div>



                {npage > 1 ? (
               
               <div className={styles.footer}>
                   <div className={styles.userBtn}>
                     <button className={styles.userPrev} onClick={prePage}>Previous</button>
                     <button className={styles.userNext} onClick={nextPage}>Next</button>
                   </div>
   
                    <div className={styles.pagination}>{currentPage} of {npage}</div>
               </div> 
                ) : null
                } 
            
        </div>
    </div>
    </>
  )
  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1);
    }
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1);
    }
  }

}

export default BlocknReports