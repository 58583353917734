import React,{ useState } from 'react'
import styled from 'styled-components';
//npm install styled-components
import { userRequest } from '../../Components/RequestMethod'
import { message } from 'antd'

const ToggleSwitch = styled.label`

position: relative;
display: inline-block;
width: 30px;
height: 17px;
//   width: 60px;
//   height: 34px;

input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Customize the appearance of the slider */
span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Default color for the switch background */
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
  //   height: 26px;
  //   width: 26px;
  height: 13px;
  width: 13px;
    left: 4px;
    bottom: 2px;
    background-color: #ffffff; /* Default color for the switch button ball */
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
}

/* When the toggle is ON */
input:checked + span {
  background-color: #ffffff; /* Change the background color when the switch is ON */
}

input:checked + span:before {
  transform: translateX(12px); /* Move the button to the right when the switch is ON */
  background-color:#77e38d;    
}
`;
//#77e38d
const CustomToggleSwitch = ({postData,fetchSingleUserData}) => {

    const [isActive, setIsActive] = useState(postData?.isActive);

  if (!postData || !postData?._id) {
    return null; // Return null or a loading indicator or an error message
  }

  //console.log(postData);

  const handleToggle = () => {
    setIsActive(!isActive);
  };
  
  // let newPostData = JSON.stringify({
  //   "postId" : postData?._id,
  //   "status": !userData.status
  // })

  const updatePostStatus = async(postData) =>{
    const newStatus = !isActive;

  await userRequest.put("/admin/post/createOrUpdate",{
    postId : postData?._id,
    userId: postData?.userId?._id,
    isActive: !postData?.isActive,
  })
    .then(()=>{
      message.success("Post updated successfully");
      setIsActive(newStatus);
      fetchSingleUserData();
      //window.location.reload();
    })
    .catch((err)=>{
      const errorMessage = err.response?.data?.message || "An error occurred" ;
      message.error(errorMessage);
    });
  }

  return (
    <div style={{display:'flex',alignItems:'center', gap:'1rem',height:'1rem',width:'8rem' }}>
    <ToggleSwitch isActive={isActive}>
      <input type="checkbox" checked={isActive} onChange={()=>updatePostStatus(postData)} />
      <span></span>
    </ToggleSwitch>
    {isActive ? 'Active' : 'Inactive'}
    </div>
  )
}

export default CustomToggleSwitch