import React,{useState} from 'react'
import { Table, Checkbox } from 'antd';
import { Link } from 'react-router-dom'
import { message } from 'antd';
import { userRequest } from '../RequestMethod';
import ReportViewDetail from '../BlocknReportViewDetailModal/ReportViewDetail/ReportViewDetail';


const ReportTest = ({reportedUser1,fetchReportedData}) => {
    //console.log(reportedUser1)

    const deleteDetail = async (userData) => {
        //console.log(userData._id)
        // await userRequest.put(`/api/reportOrBlock/getSingleReportOrBlock?_id=${}`,{postId:userData._id,deleted:true})
        //await userRequest.delete(`/api/reportOrBlock/deleteReport?_id=${userData._id}`)
        await userRequest.delete('/api/reportOrBlock/deleteReport',{data: {"_id" : userData?._id}})
         .then(() => {
           message.success("Report deleted successfully"); 
           //fetchReportedData();
           window.location.reload();
         })
         .catch((err) => {
           const errorMessage = err.response?.data?.message || "An error occurred";
           message.error(errorMessage);
         });
     };

    ////////////////////////////////////////////////////////////////////view detail Modal
    
    const [showReportViewDetailModal,setShowReportViewDetailModal] = useState(false);
    const [viewDetailData,setViewDetailData] = useState('');
    
    const handleViewDetail=(userData)=>{
      setShowReportViewDetailModal(true);
      setViewDetailData(userData);
    }
    //console.log(viewDetailData)

    const columns = [

        { title: 'UserID', dataIndex: 'column1', key: 'column1' },
        { title: 'User name', dataIndex: 'column2', key: 'column2' },
        { title: 'Email ID', dataIndex: 'column3', key: 'column3' },
        { title: 'Date', dataIndex: 'column4', key: 'column4' },
        { title: 'Category', dataIndex: 'column5', key: 'column5' },
        { title: 'Reported on', dataIndex: 'column6', key: 'column6' },
        { title: <div style={{ textAlign: 'center' }}>Actions</div>, dataIndex: 'column7', key: 'column7' },
        
      ];

      const data = reportedUser1.length > 0 ? (
        reportedUser1.map((userData) => {

          const updatedAtDate = new Date(userData?.userDetails?.updatedAt);
          const year = updatedAtDate.getFullYear();
          const month = updatedAtDate.getMonth() + 1; // Month is 0-indexed, so add 1
          const day = updatedAtDate.getDate();
          const formattedUpdatedAt = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

          // const createdAtDate = new Date(userData.userDetails.createdAt);
          // const nyear = createdAtDate.getFullYear();
          // const nmonth = createdAtDate.getMonth() + 1; // Month is 0-indexed, so add 1
          // const nday = createdAtDate.getDate();
          // const formattedCreatedAt = `${nyear}-${nmonth < 10 ? '0' : ''}${nmonth}-${nday < 10 ? '0' : ''}${nday}`;

    return{
        key: userData._id,
        column1: <div style={{width:"4rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>
          {userData?.postId?._id ? userData?.userDetails?._id : userData?.blockedByDetails?._id }</div>,
        column2: <div style={{width:"5rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>
          {userData?.postId?._id ? userData?.userDetails?.userName : userData?.blockedByDetails?.userName}</div>,
        column3: <div style={{width:"7rem",background:"",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>
          {userData?.postId?._id ? userData?.userDetails?.email : userData?.blockedByDetails?.email}</div>, 
        column4: <div style={{width:"5rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{formattedUpdatedAt}</div>,
        column5: <div style={{width:"5rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{userData?.category}</div>,
        column6: <div style={{width:"5rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis",display:"flex",justifyContent:"center"}}>
          {/* {userData?.postId?._id ? "Post" : " " }</div>, */}
          {userData?.postId?._id ? 
          (<>Post <img src='Images/i.svg' alt='Icon' style={{width:'0.65rem',marginLeft:'0.35rem'}}></img>
            </> ) : " " }</div>,
          
        column7: <div style={{width:"7rem",fontSize:"0.75rem",display:"flex",marginLeft:"3rem",gap:"1rem"}}>
            <Link to='' style={{color:"#D20815",borderBottom:"1px solid #D20815"}}
              onClick={()=>deleteDetail(userData)}>Delete</Link>
              <Link to='' style={{color:"#77e38d",borderBottom:"1px solid #77e38d"}} 
              onClick={()=>handleViewDetail(userData)}>View Detail</Link>
        </div>,
    }
    })
    ) : (           // If 'user' is empty, return an empty array to avoid errors
      []
    )
    


  return (
    <>
    <Table columns={columns}  dataSource={data} pagination={false}/>
    { showReportViewDetailModal && <ReportViewDetail viewDetailData={viewDetailData} 
    setShowReportViewDetailModal={setShowReportViewDetailModal}/>}
    </>
  )
}

export default ReportTest