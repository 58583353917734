import React from 'react'
import styles from './styles.module.css'

const BlockViewDetail = ({viewDetailData,setShowViewDetailModal}) => {
    //console.log(viewDetailData)
    
    const handleClick = ()=>{
        setShowViewDetailModal(false);
    }
    

  return (
    <>
    <div className={styles.modal_container}>
        <div className={styles.modal_pic}>
        </div> 
        <div className={styles.row}>
         <p className={styles.modal_label}>Name :</p>
         <p className={styles.modal_text}>{viewDetailData?.fullName}</p>
         </div> 
         <div className={styles.row}>
         <p className={styles.modal_label}>User Name :</p>
         <p className={styles.modal_text}>{viewDetailData?.userName}</p></div>
         <div className={styles.row}>
         <p className={styles.modal_label}>Email :</p>
         <p className={styles.modal_text}>{viewDetailData?.email}</p></div>
         
          
          <div className={styles.btn}>
            <button id={styles.cancel} onClick={()=>handleClick()}>Close</button>
            
          </div>
    </div>
    </>
  )
}

export default BlockViewDetail