import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { Table, Checkbox } from 'antd';
import CustomToggleSwitch from '../ToggleButton/CustomToggleSwitch'
import PracticeToggleSwitch from '../ToggleButton/PracticeToggleSwitch'
import { userRequest } from '../RequestMethod';
import { message } from 'antd';
import EditPostModal from '../UMEditPostModal/EditPostModal';
import DeleteSureModal from '../UMDeleteSureModal/DeleteSureModal';


const Test = ({userPost1,fetchSingleUserData}) => {
//console.log(userPost1)

///////////////////////////////////////////////////////////////////////////////

const deleteDetail = async (postData) => {
   //console.log(postData)
  await userRequest.put('/admin/post/createOrUpdate',{postId:postData._id,userId:postData.userId._id,deleted:true})
    .then(() => {
      message.success("Post deleted successfully"); 
      fetchSingleUserData();
      //window.location.reload()
    })
    .catch((err) => {
      const errorMessage = err.response?.data?.message || "An error occurred";
      message.error(errorMessage);
    });
};
////////////////////////////////////////////////////////////////////displaying deleteSureModal
const [showDeleteSureModal, setShowDeleteSureModal] = useState(false);
const [deletePostData, setDeletePostData] = useState('');

const handleDeletePost =(postData)=>{
  setShowDeleteSureModal(true);
  setDeletePostData(postData);
}
////////////////////////////////////////////////////////////////////displaying editPostModal
const [showEditPostModal, setShowEditPostModal] = useState(false);
const [editPostData, setEditPostData] = useState('');

const handleEditPost =(postData)=>{
  setShowEditPostModal(true);
  setEditPostData(postData);
}


///////////////////////////////////////////////////////////////////////////////////////////////
const columns = [
  // {
  //   title: <Checkbox />,
  //   key: 'select',
  //   render: () => <Checkbox />,
  // },
    { title: 'Video Thumbnail', dataIndex: 'column1', key: 'column1' },
    { title: 'Description', dataIndex: 'column2', key: 'column2' },
    { title: 'Place', dataIndex: 'column3', key: 'column3' },
    { title: 'Category', dataIndex: 'column4', key: 'column4' },
    { title: 'Tags', dataIndex: 'column5', key: 'column5' },
    { title: <div style={{ textAlign: 'center' }}>Actions</div>, dataIndex: 'column6', key: 'column6' },
    { title: 'Status', dataIndex: 'column7', key: 'column7' },
  ];

  
  const data = userPost1.length > 0 ? (
    
    userPost1.map((postData)=>({
      key: postData._id,
      column1: (<div style={{width:"4rem",height:"1rem",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img src={postData.url} style={{width:"70%",height:"280%"}} alt='missing'/></div>),
      column2: (<div style={{width:"8rem",background:"",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{postData.description}</div>),
      column3: (<div style={{width:"5rem",background:"",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{postData.location}</div>),
      column4: (<div style={{width:"4rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{postData?.categoryId?.categoryName}</div>),
      column5: postData.tags,
      column6: (<div style={{display:"flex",justifyContent:"center",gap:"0.5rem",lineHeight:"0.8rem",fontSize:"0.75rem"}}>
      <Link to='' style={{color:"#0C1C36",borderBottom:"1px solid #0C1C36"}}
      onClick={()=>handleEditPost(postData)}>Edit</Link>
      <Link to='' style={{color:"#0C1C36",borderBottom:"1px solid #0C1C36"}}
      onClick={()=>handleDeletePost(postData)}>Delete</Link>
      <Link to='' style={{color:"#77e38d",borderBottom:"1px solid #77e38d"}}>View Detail</Link>
      </div>),
      // column7: postData.status
      // column7: <PracticeToggleSwitch />,userId={userData._id}
      column7: <CustomToggleSwitch  postData={postData} fetchSingleUserData={fetchSingleUserData}/>,
       
    }))
    ) : (
      []
    );

    


  return (
    <>
     <Table columns={columns} dataSource={data} pagination={false}/>
     { showEditPostModal && <EditPostModal editPostData={editPostData} 
     fetchSingleUserData={fetchSingleUserData} setShowEditPostModal={setShowEditPostModal}/>}

     { showDeleteSureModal && <DeleteSureModal deletePostData={deletePostData} 
     fetchSingleUserData={fetchSingleUserData} setShowDeleteSureModal={setShowDeleteSureModal}/>}
    </>
  )
}

export default Test