import React,{ useState, useEffect } from 'react'
import styles from './styles.module.css'
import { Table, Checkbox } from 'antd';
import { userRequest } from '../RequestMethod';
import { message } from "antd";
import search from "../../Assets/Images/search.png"
import sort from "../../Assets/Images/sort.png"
import debounce from 'lodash.debounce';
import ViewDetailModal from '../SelectUserViewDetailModal/ViewDetailModal';

const SelectUser = ({userId,setUserId}) => {

  const [user ,setUser] = useState([]);

  //console.log(userId);
  const fetchData = async (searchQuery) => {
    //  await userRequest.get("/admin/customer/getAllUsers?page=1&limit=50&search")
      await userRequest.get(`/admin/customer/getAllUsers?page=1&limit=50&search=${searchQuery}`)
      .then((response) => {
        const result = response.data.data;
        setUser(result);
      // message.success("data fetched successfully");
      })

      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      });
  };

  useEffect(()=>{
     fetchData(" ")
},[]);

    //////////////////////////////////////////////////////////////applying pagination 
  
 const [currentPage, setCurrentPage] = useState(1);

 const recordsPerPage = 4;
 const lastIndex = currentPage*recordsPerPage;
 const firstIndex = lastIndex - recordsPerPage;
 const user1 = user.slice(firstIndex, lastIndex);
 const npage = user? Math.ceil(user.length/recordsPerPage): 0;

/////////////////////////////////////////////////////////////////applying search filter and debounce

const [searchText, setSearchText] = useState('');

const handleSearch = (e) => {
  setSearchText(e.target.value);
  // fetchData(e.target.value);        before debounce 
  debouncedFetchData(searchText);  
  
  if(e.target.value === ''){
    fetchData();
    //window.location.reload();
    // console.log('ho gya')
  }
};
const debouncedFetchData = debounce(fetchData, 2000);
//////////////////////////////////////////////////////////////////displaying view detail modal

const [showViewDetailModal,setShowViewDetailModal] = useState(false);
const [viewDetailData,setViewDetailData] = useState('');

const handleViewDetail =(userData)=>{
  setShowViewDetailModal(true);                  //set this true when required
  setViewDetailData(userData);
}

//////////////////////////////////////////////////////////////////////////////
//const [selectedUserId, setSelectedUserId] = useState([]);
//console.log(selectedUserId);

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // Extract the selected usernames from selectedRows and update the state
    const userIds = selectedRows.map((row) => row.column2.props.children);
    setUserId(userIds);
  },
  hideSelectAll: true,
 };

    const columns = [
        // {
        //     // title: <Checkbox />,
        //     key: 'select',
        //     render: () => <Checkbox />,
        //   },
          { title: '', dataIndex: 'column1', key: 'column1' },
          { title: '', dataIndex: 'column2', key: 'column2' },
          // { title: '', dataIndex: 'column3', key: 'column3' },
          { title: '', dataIndex: 'column4', key: 'column4' },
          
        ];

        const data = user1.length > 0 ? ( 
          user1.map((userData) => ({

              key: userData._id,
              column1: <div style={{width:"2rem",overflow:"hidden",whiteSpace:"nowrap",fontSize:"0.8rem"}}>{userData.fullName}</div>,
              column2: <div style={{visibility:"hidden"}}>{userData._id}</div>,
              //column3: '',  Ensure 'userData.email' exists before accessing
              column4: <div style={{width:"4rem",fontSize:"0.8rem",color:"#77e38d",borderBottom:"1px solid #77e38d",cursor:"pointer"}}
              onClick={()=>handleViewDetail(userData)}>View Detail</div>,
              
            
          }))
        ) : (                  //If user is empty then return empty array.
          []
        );

        // console.log(user1);

  return (
    <>
    <div className={styles.sg_container}> 

    <div className={styles.header_bar}>
                <img src={search} alt='missing'></img>
                <input type='text' className={styles.search_bar} placeholder='search' value={searchText} onChange={handleSearch} ></input>
                <img src={sort} alt='missing'></img>
            </div>
    
    <Table  rowSelection={rowSelection} columns={columns} dataSource={data} pagination={false}/>
    { showViewDetailModal && <ViewDetailModal viewDetailData={viewDetailData}
      setShowViewDetailModal={setShowViewDetailModal}/>}
    
    {npage > 1 ? (
               
               <div className={styles.footer}>
                   <div className={styles.userBtn}>
                     <button className={styles.userPrev} onClick={prePage}>Previous</button>
                     <button className={styles.userNext} onClick={nextPage}>Next</button>
                   </div>
   
                    <div className={styles.pagination}>{currentPage} of {npage}</div>
               </div> 
                ) : null
                } 
    </div>
    </>
  )

  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1);
    }
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1);
    }
  }

}

export default SelectUser