import React,{ useState } from 'react'
import styles from './styles.module.css'

const Descriptionbox = ({description= '',setDescription,characterLimitExceeded,setCharacterLimitExceeded}) => {

    // const [description, setDescription] = useState('');
    
    const maxCharacterCount = 250;
    const [characterCount, setCharacterCount] = useState(description.length);
    // const [characterLimitExceeded, setCharacterLimitExceeded] = useState(false);
    

  const handleChange = (event) => {
    const inputValue = event.target.value;
    
    if (inputValue.length <= maxCharacterCount) {
      const sanitizedInput = inputValue.replace(/\s+/g, ' ');
      setDescription(sanitizedInput);
      setCharacterCount(sanitizedInput.length);
      setCharacterLimitExceeded(false);
    }else{
      setCharacterLimitExceeded(true);
    }
    //setDescription(event.target.value);
  };
  const initialDescription = description || ''; 

  return (
    <>
     <div>
      <textarea
      className={styles.des_box}
        value={initialDescription}
        onChange={handleChange}
        placeholder="Type your message here."
        rows="8"
        cols="50"
      />
      <div className={styles.wordsLimit}>
        {/* <strong>Description:</strong> */}
        <p>Character Count: {characterCount}/{maxCharacterCount}</p>
        {characterLimitExceeded && (
            <p className={styles.errorMsg}>Characters limit exceeded!</p>
          )}
      </div>
    </div>
  
    </>
  )
}

export default Descriptionbox