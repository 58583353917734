import React from 'react'
import styles from './styles.module.css';
import { userRequest } from '../../Components/RequestMethod'
import { message } from 'antd'

const DeleteSureModal = ({fetchSingleUserData,deletePostData,setShowDeleteSureModal}) => {

    const deleteDetail = async (e) => {
        //console.log(postData)
       await userRequest.put('/admin/post/createOrUpdate',{postId:deletePostData._id,userId:deletePostData.userId._id,deleted:true})
         .then(() => {
           message.success("Post deleted successfully"); 
           setShowDeleteSureModal(false);
           fetchSingleUserData();
           //window.location.reload()
         })
         .catch((err) => {
           const errorMessage = err.response?.data?.message || "An error occurred";
           message.error(errorMessage);
         });
     };

    const handleCancel =() =>{
        setShowDeleteSureModal(false);
      }

  return (
    <>
    <div className={styles.uploadVideo_right}>
    <div className={styles.box}>

         <p>Are you sure to delete this post?</p>
         <div className={styles.buttonSection}>
         <button onClick={()=>deleteDetail()}>Yes</button>
         <button onClick={handleCancel}>Cancel</button>
         </div>

        </div>

       
    </div>
    </>
  )
}

export default DeleteSureModal