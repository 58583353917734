import React,{ useState,useEffect } from 'react';
import { BrowserRouter as Router,Routes,Route,useMatch, useNavigate } from "react-router-dom";
import './App.css';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ConfirmPassword from './pages/ForgotPassword/ConfirmPassword';
import Login from './pages/Home/Login';
import UserManagement from "./pages/UserManagement/UserManagement";
import Navbar from "./Components/Navbar/Navbar";
import AddUser from "./pages/UMAddUser/AddUser";
import ViewDetail from './pages/UMViewDetail/ViewDetail';
import VideoManagement from "./pages/VideoManagement/VideoManagement";
import VMUploadVideo from "./pages/VMUploadVideo/VMUploadVideo";
import MasterData from "./pages/MasterData/MasterData";
import Dashboard from "./pages/Dashboard/Dashboard";
import MyProfile from "./pages/MyProfile/MyProfile";
import PrivateRoute from './Components/PrivateRoutes';
import PublicRoute from './Components/PublicRoutes';
import NotificationManagement from "./pages/PushNotifications/NotificationManagement";
import AddNotifications from './pages/AddNotifications/AddNotifications';
import BlocknReports from './pages/BlocknReports/BlocknReports';
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {

 const NavbarWrapper = () => {
  const currentLocation = window.location.pathname;
  const match = ['/','/forgotPassword','/confirmPassword'].some((route)=>route===currentLocation);
  // console.log("ffafa",match,currentLocation)
  if(match){
    return null;
    }
    // else if(match = '/'){
    //   window.location.reload();
    // }
    else{
      return <PrivateRoute><Navbar /></PrivateRoute>
    }
 }

 const currentLocation = window.location.pathname ;

 useEffect(()=>{
  if ( currentLocation !== '/userManagement/viewDetail'){
    localStorage.removeItem("viewDetailDataStored");
    localStorage.removeItem("fullNameStored");
    localStorage.removeItem("userNameStored");
    localStorage.removeItem("emailStored");
    localStorage.removeItem("phoneNumberStored");
   }
 },[currentLocation])

//  const storedId = localStorage.getItem("viewDetailDataStored");
//  console.log(storedId);

  return (
    <>
    <Router>
    <div style={{ display: 'flex' }}>
      
  <NavbarWrapper />
      <Routes>
        <Route path='/' element={<PublicRoute><Login /></PublicRoute>}></Route>
        <Route path='/forgotPassword' element={<PublicRoute><ForgotPassword /></PublicRoute>}></Route>
        <Route path='/confirmPassword' element={<PublicRoute><ConfirmPassword /></PublicRoute>}></Route>
        <Route path='/dashboard' element={ <PrivateRoute><Dashboard /></PrivateRoute>}></Route>
        <Route path='/userManagement' element={<PrivateRoute><UserManagement /></PrivateRoute>}></Route>
        <Route path='/userManagement/addUser' element={<PrivateRoute><AddUser /></PrivateRoute>}></Route>
        <Route path='/userManagement/viewDetail' element={<PrivateRoute><ViewDetail /></PrivateRoute>}></Route>

        <Route path='/videoManagement' element={<PrivateRoute><VideoManagement /></PrivateRoute>}></Route>
        <Route path='/videoManagement/uploadVideo' element={<PrivateRoute><VMUploadVideo /></PrivateRoute>}></Route>
        <Route path='/masterData' element={<PrivateRoute><MasterData /></PrivateRoute>}></Route>
        <Route path='/myProfile' element={<PrivateRoute><MyProfile /></PrivateRoute>}></Route>
        <Route path='/pushNotif' element={<PrivateRoute><NotificationManagement /></PrivateRoute>}></Route>
        <Route path='/addNotif' element={<PrivateRoute><AddNotifications /></PrivateRoute>}></Route>
        <Route path='/blockReport' element={<PrivateRoute><BlocknReports /></PrivateRoute>}></Route>
        
      </Routes>
      </div>
    </Router>


     {/* <Navbar /> */}

    </>
  );
}

export default App;
