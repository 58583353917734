import React,{ useEffect } from 'react'
import { Table, Checkbox } from 'antd';
import { Link } from 'react-router-dom'
import { message } from 'antd';
import axios from 'axios'; 
import { userRequest } from '../RequestMethod';

const VMTest = ({vuser1,fetchVMData,currentStatus}) => {
  //console.log(vuser1);
  //console.log(fetchVMData());
//////////////////////////////////////////////////////////////////

   const deletePost = async (vuserData) => {
   
    // await userRequest.put('/admin/post/createOrUpdate',{postId:vuserData._id,userId:vuserData.userId._id,deleted:true})
    await userRequest.put('/admin/post/createOrUpdate',{postId:vuserData._id,userId:vuserData?.userId?._id,status:"Rejected"})
      .then(() => {
        message.success("Post deleted successfully");
        //fetchVMData();
        window.location.reload()
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  const addPost = async (vuserData) => {
   
    await userRequest.put('/admin/post/createOrUpdate',{postId:vuserData._id,userId:vuserData?.userId?._id,status:"Accepted"})
      .then(() => {
        message.success("Post accepted successfully");
        //fetchVMData();
        window.location.reload()
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      });
  };

//   useEffect(()=>{
//     fetchVMData();
// },[]);

  //////////////////////////////////////////////////////////////////////////////download Video 
  // const fs = require('fs');
  //const savePath = 'video.mp4';

  const downloadVideo = async (videoUrl) => {
    //console.log(videoUrl);
    if (!videoUrl) {
      message.error('Video does not exist.');
      return;
    }
  
  // try {
  //   window.open(videoUrl, '_blank');
  //   message.success('Video downloaded successfully.');
  // } 

  try {
    // Extract the file name before ".m3u8" from the URL
    const fileName = videoUrl.split('/').pop().replace('.m3u8', '');
    

    // Check if the fileName is not empty
    if (!fileName) {
        message.error('Invalid video URL.');
        return;
    }

     // Construct the new URL with the desired format
     const newUrl = `https://okomobucket.s3.ap-south-1.amazonaws.com/inputs/${fileName}.mp4`;
 
     // Fetch the video from the new URL
    //  const response = await fetch(newUrl);
    //  console.log(response)

    if (newUrl) {
      window.open(newUrl, '_blank');
      message.success('Video download started.');
    } else {
      message.error('Video does not exist or an error occurred while starting the video download.');
    }
   }

  catch (error) {
    //console.error('An error occurred:', error);
    message.error('An error occurred while starting the video download');
  } 
};

  /////////////////////////////////////////////////////////////////////////////////////

const columns = [

    { title: 'Video Thumbnail', dataIndex: 'column1', key: 'column1' },
    { title: 'User Name', dataIndex: 'column2', key: 'column2' },
    { title: 'Name', dataIndex: 'column3', key: 'column3' },
    { title: 'Email Id', dataIndex: 'column4', key: 'column4' },
    { title: 'Description', dataIndex: 'column5', key: 'column5' },
    { title: 'Place', dataIndex: 'column6', key: 'column6' },
    { title: 'Category', dataIndex: 'column7', key: 'column7' },
    { title: 'Tags', dataIndex: 'column8', key: 'column8' },
    { title: <div style={{ textAlign: 'center' }}>Actions</div>, dataIndex: 'column9', key: 'column9' },
    
  ];

  //console.log(vuser1);
  const data = vuser1.length > 0 ? (
    vuser1.map((vuserData) => {
      const categoryName = vuserData?.categoryId?.categoryName || "N/A";
    return {
    key: vuserData._id,
    column1: <div style={{width:"4rem",display:"flex",justifyContent:"center",alignItems:"center"}}><img src={vuserData.url} alt='missing' width='50'height='40'></img></div>,
    column2: <div style={{width:"4rem",fontSize:"0.75rem"}}>{vuserData?.userId?.userName}</div>,
    column3: <div style={{width:"4rem",background:"",fontSize:"0.75rem"}}>{vuserData?.userId?.fullName}</div>, // Ensure 'userData.email' exists before accessing
    column4: <div style={{width:"9rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{vuserData?.userId?.email}</div>,
    column5: <div style={{width:"5rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{vuserData.description}</div>,
    column6: <div style={{width:"3rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{vuserData.location}</div>,
    
    column7: <div style={{width:"3.5rem",fontSize:"0.75rem"}}>{categoryName}</div>,
    column8: <div style={{width:"2.5rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{vuserData.tags[0]}</div>,
    
    // column8: <div style={{width:"2.5rem",fontSize:"0.75rem"}}>{JSON.parse(vuserData.tags[0])[0]}</div>,
    column9: (<div style={{width:"9rem",display:"flex",justifyContent:"center",gap:"0.45rem",lineHeight:"0.8rem",fontSize:"0.60rem"}}>
    {/* <Link to='' style={{color:"#000000",borderBottom:"1px solid black"}}>Watch</Link> */}
    {currentStatus === "Requested" ? 
    <Link to='' style={{color:"#000000",borderBottom:"1px solid black"}}
    onClick={()=>addPost(vuserData)}>Add</Link> : " " }

    {currentStatus === "Rejected" ? " " :
    <Link to='' style={{color:"#000000",borderBottom:"1px solid black"}}
    onClick={()=>deletePost(vuserData)}>Reject</Link>}

    <Link to='' style={{color:"#77e38d",borderBottom:"1px solid #77e38d"}} 
    onClick={() => downloadVideo(vuserData.videoUrl)}>Download Video</Link>
    </div>),
   }
  })
) : (           // If 'user' is empty, return an empty array to avoid errors
  []
);


  return (
    <>
    <Table columns={columns} dataSource={data} pagination={false} />
    </>
  )
}

export default VMTest