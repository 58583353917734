import React, { useState } from "react";
import { message, Upload, Progress } from "antd";
import addIcon from "../../Assets/Images/addicon.png";
import styles from "./styles.module.css";

const { Dragger } = Upload;

const encodeCredentials = (username, password) => {
  const credentials = `${username}:${password}`;
  const encodedCredentials = btoa(credentials);
  return `Basic ${encodedCredentials}`;
};

const MyUploadVido = ({ videoURL, setVideoURL }) => {
  const [u8videoURL, setu8VideoURL] = useState("");
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [fileList, setFileList] = useState([]);
  let newUrl = ""; //declaring

  //'https://okomo.onrender.com/api/utils/uploadFile'    (previous)
  //'http://52.66.61.250:4326/api/utils/uploadVideoFile'  (suggested)
  //"https://admin.okomo360.com/api/utils/uploadFile"   (latest)
  // 'https://admin.okomo360.com/api/utils/uploadVideoFile'
  //console.log(videoURL);

  const props = {
    name: "file",
    multiple: false,
    action: "https://admin.okomo360.com/api/utils/uploadVideoFile",
    headers: {
      Authorization: encodeCredentials("OKOMO", "QWERTYOKOMOPOIUTYMKOL"),
    },
    fileList: fileList,
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true,
    },
    accept: "video/*", // Accept only video files

    beforeUpload(file) {
      if (file.type.includes("video/mp4")) {
        // console.log('file',file);
        return true; // Allow only video files
      } else if (file.type.includes("image/jpeg")) {
        //console.log('image uploading not required');
        message.error("Please upload a video file");
        // console.log('file',file);
        return false; // Reject the file and don't add it to the fileList
      } else {
        return false;
      }
    },
    onChange(info) {
      const { status, percent } = info.file;
      if (status === "uploading") {
        setUploading(true);
        setUploadPercent(Math.floor(percent));
        setFileList([info.file]);
        //   console.log('video is uploading')
      } else if (status === "done") {
        console.log("info", info);
        setu8VideoURL(info.file.response.url); //change here for u8 format
        setIsVideoUploaded(true);
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploading(false);
        setFileList([]);
        //console.log(info.file.response.url);
        //console.log(videoURL);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setFileList([]);
      } else if (status === "removed") {
        setu8VideoURL(""); //change here for u8 format
        setIsVideoUploaded(false);
        setUploading(false);
        setFileList([]);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  //showUploadList={!!videoURL}
  //disabled={isVideoUploaded}

  // if (videoURL){
  //   const fileName = videoURL.split('/').pop().replace('.m3u8', '');
  //   newUrl = `https://okomobucket.s3.ap-south-1.amazonaws.com/inputs/${fileName}.mp4`;
  // }

  if (u8videoURL) {
    // const fileName = u8videoURL.split('/').pop().replace('.m3u8', '');
    // newUrl = `https://okomobucket.s3.ap-south-1.amazonaws.com/inputs/${fileName}.mp4`;
    // setVideoURL(newUrl);
    setVideoURL(u8videoURL);
    setu8VideoURL("");
    setIsVideoUploaded(false);
  }

  //videoURL
  return (
    <>
      <div className={styles.Vupload}>
        <Dragger
          {...props}
          showUploadList={!!videoURL}
          disabled={isVideoUploaded}
        >
          {videoURL ? (
            <video width="220" height="80" controls>
              <source src={videoURL} type="video/mp4"></source>
            </video>
          ) : (
            <p className="ant-upload-drag-icon">
              <img className={styles.Vicon} src={addIcon} alt="Add Icon"></img>
              <p className="ant-upload-text">
                <span className={styles.text}>Choose/Upload video</span>
              </p>
            </p>
          )}
          {uploading && <Progress percent={uploadPercent} />}
        </Dragger>
      </div>
    </>
  );
};

export default MyUploadVido;
