import React,{ useState } from 'react'
import { Table, Checkbox } from 'antd';
import { Link } from 'react-router-dom'
import BlockViewDetail from '../BlocknReportViewDetailModal/BlockViewDetail';

const BlockTest = ({blockedUser1}) => {

  const [showBlockViewDetailModal,setShowBlockViewDetailModal] = useState(false);
  const [viewDetailData,setViewDetailData] = useState('');

  const handleViewDetail =(userData)=>{
    setShowBlockViewDetailModal(true);                  //set this true when required
    setViewDetailData(userData);
  }

    //console.log(blockedUser1)
    const columns = [

        { title: 'UserID', dataIndex: 'column1', key: 'column1' },
        { title: 'User name', dataIndex: 'column2', key: 'column2' },
        { title: 'Email ID', dataIndex: 'column3', key: 'column3' },
        { title: 'Date', dataIndex: 'column4', key: 'column4' },
        { title: 'Blocked by', dataIndex: 'column5', key: 'column5' },
        { title: <div >Actions</div>, dataIndex: 'column6', key: 'column6' },
        
      ];

      const data = blockedUser1.length > 0 ? (
        blockedUser1.map((userData) => {
        //userData.userDetails.createdAt
          const createdAtDate = new Date(userData?.createdAt);
          const year = createdAtDate.getFullYear();
          const month = createdAtDate.getMonth() + 1; // Month is 0-indexed, so add 1
          const day = createdAtDate.getDate();
          const formattedCreatedAt = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
          
          
    return {
        key: userData._id,
        column1: <div style={{width:"4rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{userData?.blockedByDetails?._id}</div>,
        column2: <div style={{width:"4rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{userData?.blockedByDetails?.userName}</div>,
        column3: <div style={{width:"8rem",background:"",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{userData?.blockedByDetails?.email}</div>, 
        column4: <div style={{width:"4rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{formattedCreatedAt}</div>,
        column5: <div style={{width:"4rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{userData?.userDetails?.fullName}</div>,
        column6: <div style={{width:"4rem",fontSize:"0.75rem",display:"flex",justifyContent:"center"}}>
              <Link to='' style={{color:"#77e38d",borderBottom:"1px solid #77e38d"}} 
              onClick={()=>handleViewDetail(userData)}>View Detail</Link>
        </div>,
    }
    })
    ) : (           // If 'user' is empty, return an empty array to avoid errors
      []
    )
    


  return (
    <>
    <Table columns={columns}  dataSource={data} pagination={false}/>
    { showBlockViewDetailModal && <BlockViewDetail viewDetailData={viewDetailData} 
    setShowBlockViewDetailModal={setShowBlockViewDetailModal} />}
    </>
  )
}

export default BlockTest