import React,{ useState,useEffect,useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from "./styles.module.css"
import Search from '../../Components/Search/Search'
import { Table, Checkbox } from 'antd';
import { Link } from 'react-router-dom'

import PracticeToggleSwitch from '../../Components/ToggleButton/PracticeToggleSwitch'
import { userRequest } from '../../Components/RequestMethod'
import { message } from "antd";
import EditUserModal from '../../Components/EditUserModal/EditUserModal';
import ViewDetailModal from '../../Components/UMViewDetailModal/ViewDetailModal';
import UploadVideoModal from '../../Components/UMUploadVideoModal/UploadVideoModal';
import debounce from 'lodash.debounce';
import { Select } from 'antd'; 
import { MyContext } from '../../myContext';


const { Option } = Select;

const UserManagement = () => {
  
  const navigate = useNavigate();

  const [user ,setUser] = useState([]);

  //console.log(user);
  const [selectedOption, setSelectedOption] = useState(5);
  //console.log(selectedOption);

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    setCurrentPage(1);
    // window.location.reload();
  };

  ////////////////////////////////////////////////////////displaying edit user modal
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [editingUserData, setEditingUserData] = useState();
    
  const editUser=(userData)=>{
    setShowEditUserModal(true);
    setEditingUserData(userData);
  }
/////////////////////////////////////////////////////////////displaying view details modal to viewDetail page
const [showViewDetailModal, setShowViewDetailModal] = useState(false);
// const [viewDetailData, setViewDetailData] = useState();    passed it to MyContext
const {viewDetailData, setViewDetailData} = useContext(MyContext);    

// const viewDetail=(userData)=>{       for Modal
//   setShowViewDetailModal(true);
//   setViewDetailData(userData);
// }

const viewDetailPage=(userData)=>{
  setViewDetailData(userData);
  localStorage.setItem("viewDetailDataStored",userData?._id);
  localStorage.setItem("fullNameStored",userData?.fullName);
  localStorage.setItem("userNameStored",userData?.userName);
  localStorage.setItem("emailStored",userData?.email);
  localStorage.setItem("phoneNumberStored",userData?.phoneNumber);

}
//viewDetailData?._id

//console.log(viewDetailDataStored)

/////////////////////////////////////////////////////////////displaying upload video modal
const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);
const [uploadVideoData, setUploadVideoData] = useState();

const uploadVideo=(userData)=>{
  setShowUploadVideoModal(true);
  setUploadVideoData(userData);
}

 //////////////////////////////////////////////////////////////applying pagination 
  
 const [currentPage, setCurrentPage] = useState(1);

 const recordsPerPage = selectedOption;
 const lastIndex = currentPage*recordsPerPage;
 const firstIndex = lastIndex - recordsPerPage;
 const user1 = user.slice(firstIndex, lastIndex);
 const npage = user? Math.ceil(user.length/recordsPerPage): 0;

 
  ////////////////////////////////////////////applying get API with searchQuery


  const fetchData = async (searchQuery) => {
    //  await userRequest.get("/admin/customer/getAllUsers?page=1&limit=50&search")
      await userRequest.get(`/admin/customer/getAllUsers?page=1&limit=500&search=${searchQuery}`)
      .then((response) => {
        const result = response.data.data;
        setUser(result);
      // message.success("data fetched successfully");
      })

      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      });
  };

  useEffect(()=>{
     fetchData(" ")
},[]);

///////////////////////////////////////////////////////applying search filter & debounce


const [searchText, setSearchText] = useState('');


const handleSearch = (e) => {
  setSearchText(e.target.value);
  // fetchData(e.target.value);        before debounce 
  debouncedFetchData(searchText);  
  
  if(e.target.value === ''){
    //fetchData();
    window.location.reload();
    // console.log('ho gya')
  }
};
const debouncedFetchData = debounce(fetchData, 2000);


//////////////////////////////////////////////////////////////defining table 

 

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      setSelectedRows(data.map((row) => row.key));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelection = (e, key) => {
    // console.log("ehjahga",e,key)
    if (e.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, key]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowKey) => rowKey !== key)
      );
    }
  };

    const columns = [
      // {
      //   title: <Checkbox onChange={(e) => handleSelectAll(e.target.checked)}
      //   checked={selectAll}/>,
      //   key: 'select',
      //   render: (record,index) => {
      //     return (
      //       <Checkbox onChange={(e) => handleRowSelection(e, record.key)}
      //     checked={selectedRows.includes(record.key)}/>
      //     )
      //   } ,
      // },
        { title: <div style={{lineHeight:"0.5rem"}}>User Name</div>, dataIndex: 'column1', key: 'column1' },
        { title: 'Name', dataIndex: 'column2', key:  'column2' },
        { title: 'Email Id', dataIndex: 'column3', key: 'column3' },
        { title: 'Public Post', dataIndex: 'column4', key: 'column4' },
        { title: 'Private Post', dataIndex: 'column5', key: 'column5' },
        { title: <div style={{ textAlign: 'center' }}>Actions</div>, dataIndex: 'column6', key: 'column6' },
        { title: 'Status', dataIndex: 'column7', key: 'column7' },
        
      ];
         
      //  const data = user1.length > 0 ? (
         const data = user1.length > 0 ? (
          user1.map((userData) => ({
            
          key: userData._id,
          column1: <div style={{width:"5rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{userData.userName}</div>,
          column2: <div style={{width:"5rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{userData.fullName}</div>,
          column3: <div style={{width:"10rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{userData.email}</div>, 
          column4: <div style={{marginLeft:"1.5rem"}}>{userData.postCount}</div>,
          column5: <div style={{marginLeft:"1rem"}}>-</div>,
          //  /userManagement/videos 
          //  /userManagement/uploadVideo
          column6: (<div style={{display:"flex",gap:"0.35rem",lineHeight:"0.8rem",fontSize:"0.75rem"}}>
          <Link to='/userManagement/viewDetail' style={{color:"#000000",borderBottom:"1px solid black"}}
          onClick={()=>viewDetailPage(userData)}>View Detail</Link>
           {/* onClick={()=>viewDetailPage(userData)}>View Detail</Link> */}
           

          <Link to='' style={{color:"#000000",borderBottom:"1px solid black"}}
          onClick={()=>uploadVideo(userData)}>Upload Video</Link>

          <Link to='' style={{color:"#77e38d",borderBottom:"1px solid #77e38d"}} 
          onClick={()=>editUser(userData)}>Edit</Link>
          </div>),
          column7: <PracticeToggleSwitch userData={userData} />,
          
          
        }))
      ) : (           // If 'user' is empty, return an empty array to avoid errors
        []
      );
  

  return (
    <>
      
        <div className={styles.um_right}>

            <Search searchText={searchText} handleSearch={handleSearch} />  {/* Search as component used here*/}
            
            <div className={styles.header}>
                <p id={styles.um_text}>User Management</p>
                <div className={styles.btn}>
                <Select
                value={selectedOption}
                onChange={handleOptionChange}
                 style={{ width: 125 ,border:"0.70px solid #77e38d",borderRadius:"8px",color:"#77e38d",
                fontSize:"0.65rem"}}
                 >
                {/* <Option value="option1" style={{fontSize:"0.65rem",color:"#77e38d"}}>No of records</Option> */}
                <Option value={5}>5</Option>
                <Option value={8}>8</Option>
                 <Option value={10}>10</Option>
                 </Select>
                    {/* <button id={styles.records}>No of records<img src='Images/iconright.png' alt='missing'></img></button> */}
                    <button id={styles.filter}><img src='Images/filter.png' alt='missing'></img>Filter</button>
                    <button id={styles.addUser} onClick={() => navigate('/userManagement/addUser')}>Add User</button>
                </div>
            </div>
            
            <div className={styles.form_main}>
            <div className={styles.form_body}> 
            <div className={styles.tab}>

            <Table columns={columns} dataSource={data} pagination={false}/> {/* Table defined above used here*/}
            {showEditUserModal && <EditUserModal setShowEditUserModal={setShowEditUserModal}
            editingUserData={editingUserData} fetchData={fetchData}/>}

            {showViewDetailModal && <ViewDetailModal viewDetailData={viewDetailData}/>}

            </div>
            {showUploadVideoModal && <UploadVideoModal uploadVideoData={uploadVideoData}
            setShowUploadVideoModal={setShowUploadVideoModal} fetchData={fetchData}/>}
            </div>

            {npage > 1 ? (
               
            <div className={styles.footer}>
                <div className={styles.userBtn}>
                  <button className={styles.userPrev} onClick={prePage}>Previous</button>
                  <button className={styles.userNext} onClick={nextPage}>Next</button>
                </div>

                 <div className={styles.pagination}>{currentPage} of {npage}</div>
            </div> 
             ) : null
             } 
            

        </div>
        </div>
        
    </>
  )
  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1);
    }
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1);
    }
  }
}

export default UserManagement