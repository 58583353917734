import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import Test from "../../Components/Table/Test";
import Ion from "../../Assets/Images/ion.jpg";
import UploadVideoModal from "../../Components/UMUploadVideoModal/UploadVideoModal";
import { userRequest } from "../../Components/RequestMethod";
import { message } from "antd";
import { MyContext } from "../../myContext";

const ViewDetail = () => {
  const { viewDetailData, setViewDetailData } = useContext(MyContext);
  //console.log(viewDetailData);
  const navigate = useNavigate();
  const [userPost, setUserPost] = useState([]);
  //console.log(userPost);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStatus, setCurrentStatus] = useState("");
  const [selectedButton, setSelectedButton] = useState("All");

  const recordsPerPage = 3;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const userPost1 = userPost.slice(firstIndex, lastIndex);
  const npage = userPost ? Math.ceil(userPost.length / recordsPerPage) : 0;

  ////////////////////////////////////////////////////////////////////////local storage concept

  // localStorage.setItem("viewDetailDataStored",viewDetailData?._id); This part on UM line-57
  const storedId = localStorage.getItem("viewDetailDataStored");
  const fullNameStored = localStorage.getItem("fullNameStored");
  const userNameStored = localStorage.getItem("userNameStored");
  const emailStored = localStorage.getItem("emailStored");
  const phoneNumberStored = localStorage.getItem("phoneNumberStored");

  // const currentLocation = window.location.pathname ;  sent to App.js

  // useEffect(()=>{
  //   if ( currentLocation !== '/userManagement/viewDetail'){
  //     localStorage.removeItem("viewDetailDataStored")
  //   }
  // },[currentLocation])
  //console.log(storedId);

  const dataId = viewDetailData?._id ? viewDetailData?._id : storedId;

  ///////////////////////////////////////////////////////////////////////////////////////

  const fetchSingleUserData = async () => {
    await userRequest
      .get(
        `/admin/post/getAllPost?search=&userId=${dataId}&status=${currentStatus}`
      )
      // await userRequest.get(`/admin/post/getAllPost?search=&userId=${viewDetailData?._id}`) working
      //  await userRequest.get('/admin/post/getAllPost?search=&userId=64da251008b8b58990b1dbc0')
      .then((response) => {
        const allPosts = response.data.data;
        setUserPost(allPosts);
        // message.success("data fetched successfully");
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        //console.log(errorMessage)
        message.error(errorMessage);
      });
  };

  //console.log(userPost1[0]?._id);

  useEffect(() => {
    fetchSingleUserData();
  }, [currentStatus]);

  /////////////////////////////////////////////////////////////displaying upload video modal
  const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);
  const [uploadVideoData, setUploadVideoData] = useState();

  const uploadVideo = (userData) => {
    setShowUploadVideoModal(true);
    setUploadVideoData(userData);
  };
  const handleAll = () => {
    setCurrentStatus("");
    //fetchVMData(searchText);
    setSelectedButton("All");
    //console.log('in accepted');
    setCurrentPage(1);
  };

  const handlePrivate = () => {
    setCurrentStatus("Private");
    //fetchVMData(searchText);
    setSelectedButton("Private");
    //console.log('in rejected');
    setCurrentPage(1);
  };

  return (
    <>
      <div className={styles.user_right}>
        {/*<Search />                       Search as component used here  */}

        <div className={styles.header}>
          <div className={styles.header_left}>
            <div className={styles.profPic}>
              <img
                src={viewDetailData?.profilePic}
                style={{ height: "100%", width: "100%", borderRadius: "50%" }}
              ></img>
            </div>
            <div className={styles.macintosh}>
              <p className={styles.macintosh1}>
                {viewDetailData?.fullName
                  ? viewDetailData?.fullName
                  : fullNameStored}
              </p>
              <p className={styles.macintosh2}>
                @
                {viewDetailData?.userName
                  ? viewDetailData?.userName
                  : userNameStored}
              </p>
            </div>
          </div>

          <div className={styles.header_right}>
            <button onClick={() => uploadVideo(viewDetailData)}>
              Upload Videos
            </button>
          </div>
        </div>

        <div className={styles.mainbody}>
          <div className={styles.body}>
            <p className={styles.userInfoText}>User Infos</p>
            <div className={styles.userInfo}>
              <div className={styles.userPhone}>
                <p>Phone Number</p>
                <p className={styles.userDet}>
                  {viewDetailData?.phoneNumber
                    ? viewDetailData?.phoneNumber
                    : phoneNumberStored}
                </p>
              </div>
              <div className={styles.userEmail}>
                <p>E-Mail</p>
                <p className={styles.userDet}>
                  {viewDetailData?.email ? viewDetailData?.email : emailStored}
                </p>
              </div>
            </div>
            <p className={styles.userVideoText}>Videos</p>
            <div className={styles.header_bottom}>
              <button
                className={selectedButton === "All" ? styles.req_video : ""}
                onClick={handleAll}
              >
                All Videos
              </button>
              <button
                className={selectedButton === "Private" ? styles.req_video : ""}
                onClick={handlePrivate}
              >
                Requested Videos
              </button>
            </div>

            <div className={styles.userTable}>
              <Test
                userPost1={userPost1}
                fetchSingleUserData={fetchSingleUserData}
              />{" "}
              {/* Table as component used here  */}
            </div>
          </div>

          {showUploadVideoModal && (
            <UploadVideoModal
              uploadVideoData={uploadVideoData}
              storedId={storedId}
              fullNameStored={fullNameStored}
              userNameStored={userNameStored}
              setShowUploadVideoModal={setShowUploadVideoModal}
              fetchSingleUserData={fetchSingleUserData}
            />
          )}

          {npage > 1 ? (
            <div className={styles.footer}>
              <div className={styles.userBtn}>
                <button className={styles.userPrev} onClick={prePage}>
                  Previous
                </button>
                <button className={styles.userNext} onClick={nextPage}>
                  Next
                </button>
              </div>
              <div className={styles.pagination}>
                {currentPage} of {npage}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
};

export default ViewDetail;
