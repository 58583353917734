import React,{useState} from 'react'
import styles from './styles.module.css'

const NotificationDetail = ({setShowNotificationDetailModal,notificationDetail,fdate}) => {
    //console.log(notificationDetail)

    const handleCancel = ()=>{
        setShowNotificationDetailModal(false);
    }

  return (
    <>
    <div className={styles.addUser_right}>
    <div className={styles.header}>
        <p className={styles.text}>Notification detail</p>
        
    </div>

    <div className={styles.body}>
                {/*<p>Videos</p>                       Videos is hidden here  */}  
                <div className={styles.white_body}>
                  <div className={styles.text1}>
                    <text>{notificationDetail.title}</text>
                    <p>{fdate}</p>
                    <p>{notificationDetail.time}</p>
                  </div>
                    
                    <div className={styles.text2}>Message</div>
                    <text>{notificationDetail.message}</text>
                </div>
            </div>

    </div>
    </>
  )
}

export default NotificationDetail