import React,{useState,useEffect} from 'react'
import styles from './styles.module.css';
import EditUploadVido from '../../Components/Upload/EditUploadVido';
import UploadThumbnail from '../../Components/Upload/UploadThumbnail'
import DescriptionBox from '../../Components/DescriptionBox/Descriptionbox'
import RadioButton from '../../Components/RadioButton/RadioButton'
import { userRequest } from '../../Components/RequestMethod'
import { message } from 'antd'

const EditPostModal = ({setShowEditPostModal,editPostData,fetchSingleUserData}) => {
    //console.log(editPostData.videoUrl);
    
    // let newUrl = '' ;
    // if (editPostData.videoUrl){
    //   const fileName = editPostData.videoUrl.split('/').pop().replace('.m3u8', '');
    //   newUrl = `https://okomobucket.s3.ap-south-1.amazonaws.com/inputs/${fileName}`;
    // }
   

  const [videoURL,setVideoURL] = useState(editPostData.videoURL);
  //console.log(videoURL);
  const [thumbnailURL,setThumbnailURL] = useState(editPostData.url || '');
  const [description, setDescription] = useState(editPostData.description || '');
  const [place ,setPlace] = useState(editPostData.location || '');
  const [category,setCategory] = useState(editPostData.categoryId._id || '');
  const [tag,setTag] = useState(editPostData.tags);
  const [selectedValue, setSelectedValue] = useState(null); 

  //console.log(editPostData.categoryId.categoryName)

  let newPostData = JSON.stringify({
    "postId" : editPostData._id,
    "userId" : editPostData?.userId?._id,
    "videoUrl" : videoURL,
    "url" : thumbnailURL,
    "description" : description,
    "location" : place,
    "categoryId" : category,
    "tags" : tag,
    "isPublic" : selectedValue,
  })

  const updatePost = async(e) =>{
    if (!videoURL) {
      message.error("Please upload a video before publishing.");
      return; // Stop execution
    }
    if (!thumbnailURL) {
      message.error("Please upload a thumbnail before publishing.");
      return; // Stop execution
    }
    if (!category) {
      message.error("Please select a category before publishing.");
      return; // Stop execution
    }
    else{

    await userRequest.put("/admin/post/createOrUpdate",newPostData)
    .then(()=>{
      message.success("Post updated successfully");
      setShowEditPostModal(false);
      fetchSingleUserData();
      //window.location.reload();
    })
    .catch((err)=>{
      const errorMessage = err.response?.data?.message || "An error occurred" ;
      message.error(errorMessage);
    });
  }}

  const handleCancel =() =>{
    setShowEditPostModal(false);
  }

    ///////////////////////////////////////////////////////////////Only to fetch category Id
    const [user,setUser] = useState('');

    const fetchCategoryData = async () => {
      
      await userRequest.get("/api/category/getAllCategory?page=1&limit=10&search")
        .then((response) => {
          const result = response.data.data;
          setUser(result);
        // message.success("data fetched successfully");
        })
        .catch((err) => {
          const errorMessage = err.response?.data?.message || "An error occurred";
          message.error(errorMessage);
        });
    };
  
    useEffect(()=>{
      fetchCategoryData()
  },[]);

  return (
    <>
     <div className={styles.uploadVideo_right}>
      
      <div className={styles.header}>
              <div className={styles.header_left}>
              <div className={styles.profPic}>
              {/* <img src={Ion}></img> */}
              </div>
              <div className={styles.macintosh}>
              <p className={styles.macintosh1}>{editPostData?.userId?.fullName}</p>
              <p className={styles.macintosh2}>@{editPostData?.userId?.fullName}</p>
              </div>
              </div>

              <div className={styles.header_right}>
              <button id={styles.btn_clear} onClick={handleCancel}>Cancel</button>
              <button id={styles.btn_publish} onClick={()=>updatePost()}>Update</button>
              </div>
        </div>
        
         
        <div className={styles.uploadV_main}>
      <div className={styles.uploadV_body}> 
      
      
       <div className={styles.uploadV_video}>
      <EditUploadVido videoURL={videoURL} setVideoURL={setVideoURL}/>   {/*  UploadVideo as component used here  */}
      <UploadThumbnail thumbnailURL={thumbnailURL} setThumbnailURL={setThumbnailURL}/>   {/* UploadThumbnail as component used here  */}
      <RadioButton selectedValue={selectedValue} setSelectedValue={setSelectedValue} /> {/* Radiobutton as component used here  */}
      </div>

       <DescriptionBox description={description} setDescription={setDescription}/>  {/* Description as component used here  */}

       <div className={styles.form_row1}>
       <div className={styles.form_element}>
          <label className={styles.form_label}>Places</label>
          <input type='text' className={styles.form_input} value={place}
          onChange={(e)=>setPlace(e.target.value)}></input>
        </div>

        <div className={styles.form_element}>
          <label className={styles.form_label}>Category</label>
          <select className={styles.form_input} value={category}
           onChange={(e)=>setCategory(e.target.value)}>
            <option value=""></option>
            {user.length > 0 ? (
            user.map((userData)=>(
              // <option  key={userData._id} value={userData._id}>{userData.categoryName}</option>   
              <option  key={userData._id} value={userData._id}
              >{userData.categoryName}</option>  
            ))) : ([])
            }
          </select>
        </div>

        <div className={styles.form_element}>
          <label className={styles.form_label}>Tags</label>
          <input type='text' className={styles.form_input} value={tag}
          onChange={(e)=>setTag(e.target.value)}></input>
        </div>
        </div>

        

      </div>
      </div>
      
     </div>
    </>
  )
}

export default EditPostModal