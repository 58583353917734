import { useState,createContext } from 'react';

const MyContext = createContext();

const MyProvider = ({children}) => {
    
    const[showAddCategoryModal,setShowAddCategoryModal] = useState(false);
    const [viewDetailData, setViewDetailData] = useState();

return (
    <MyContext.Provider value={{showAddCategoryModal,setShowAddCategoryModal,viewDetailData, setViewDetailData}}>
        {children}
    </MyContext.Provider>
)
}

export {MyContext,MyProvider};