import React,{ useState } from 'react'
import { Table, Checkbox } from 'antd';



const AllNotifTest = ({ notifData1 }) => {
    //console.log(notifData1)

    const columns = [

        { title: 'Title', dataIndex: 'column1', key: 'column1' },
        { title: 'Date', dataIndex: 'column2', key: 'column2' },
        { title: 'Message', dataIndex: 'column3', key: 'column3' },
        { title: 'Sent to', dataIndex: 'column4', key: 'column4' },
        //{ title: <div style={{ textAlign: 'center' }}>Actions</div>, dataIndex: 'column5', key: 'column5' },
        
        
      ];

      const data = notifData1?.length > 0 ? (
        notifData1.map((notifData) => {

          const createdAtDate = new Date(notifData.date);
          const year = createdAtDate.getFullYear();
          const month = createdAtDate.getMonth() + 1; // Month is 0-indexed, so add 1
          const day = createdAtDate.getDate();
          const formattedDate = ` ${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
         
        return {
        key: notifData._id,
        column1: <div style={{width:"6rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{notifData.title}</div>,
        column2: <div style={{width:"6rem",fontSize:"0.75rem"}}>{formattedDate}</div>,
        column3: <div style={{width:"9rem",background:"",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{notifData.message}</div>, // Ensure 'userData.email' exists before accessing
        column4: <div style={{width:"6rem",fontSize:"0.75rem",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{notifData?.users[0]?._id ? notifData?.users[0]?._id : 'All users'}</div>,
          }
     })
     ) : (           // If 'user' is empty, return an empty array to avoid errors
       []
     )

  return (
    <>
    
     <Table columns={columns}  pagination={false} dataSource={data}/>
     
    </>
  )
}

export default AllNotifTest