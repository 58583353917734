import React,{ useState } from 'react'
import style from './styles.module.css'

const RadioButton = ({selectedValue, setSelectedValue}) => {
    //console.log(selectedValue)
    
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };
  
  return (
    <>
    <div className={style.radio_container}>
     {/* <label className={selectedValue === "false" ? `${style.radio_label} ${style.selected}` : style.radio_label}> */}
     <label className={style.radio_label}>
        <input
          type="radio"
          value="Push"
          checked={selectedValue === "Push"}
          onChange={handleChange}
        />
        <div className={style.radio_button}></div>
        Push Notification
      </label>
      
      
      {/* <label className={style.radio_label}> */}
      <label className={style.radio_label}>
        <input
          type="radio"
          value="Custom"
          checked={selectedValue === "Custom"}
          onChange={handleChange}
        />
        <div className={style.radio_button}></div>
        Custom Notification
      </label>
      </div>
      
    </>
  )
}

export default RadioButton