import React,{ useState,useEffect } from 'react'
import styles from './styles.module.css'
import { userRequest } from '../RequestMethod';
import { message } from "antd";

const Modal = ({setShowAddCategoryModal,fetchMasterData}) => {

  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState('');
  const [categoryTouched, setCategoryTouched] = useState(false);

  const nameRegex = /^[A-Za-z]{2,50}( [A-Za-z]{2,50}){0,20}$/;

  useEffect(() => {
    if (categoryTouched){
      if(!category.trim()){
        setCategoryError('Category is required');
      }
    else if(!nameRegex.test(category)) {
      setCategoryError('Enter correct category(with 2-50 char.)');
    } else {
      setCategoryError('');
    }}
  },[category,categoryTouched]);


  let data = JSON.stringify({
    "categoryName": category
  });

  const saveCategory = async (e) => {
    setCategoryTouched(true);

    if(!category.trim()){
      message.error('Category is required');
    }
    else if(!nameRegex.test(category)){
      message.error('Please enter correct category name(with 2-50 char.)');
    }
    else{
    //  e.preventDefault();
   await userRequest.post("/api/category/createCategory", data)
      .then(() => {
        message.success("Category added successfully");
        setShowAddCategoryModal(false);
        fetchMasterData();
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      });
    }};

    const handleCancel=()=>{
        setShowAddCategoryModal(false);
        // localStorage.removeItem("showAddCategoryModal");
        // window.location.reload();
    }

  return (
    <>
    <div className={styles.modal_container}>
        <div className={styles.modal_pic}>
        <img src='Images/icon_md.png'></img>
        <p>Add Category</p>
        </div>  
         <label className={styles.modal_label}>Category Name</label>
          <input type='text' name='categoryName' placeholder='' value={category} onChange={(e) => setCategory(e.target.value)}
          className={styles.modal_input}
          onBlur={()=>setCategoryTouched(true)}></input>
          { categoryError && categoryTouched &&(
                  <span className={styles.error_message}>{categoryError}</span>
                )}
          <div className={styles.btn}>
            <button id={styles.cancel} onClick={()=>handleCancel()}>Cancel</button>
            <button id={styles.save} onClick={()=>saveCategory()}>Save</button>
          </div>
    </div>
    </>
  )
}

export default Modal