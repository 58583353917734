import React,{ useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from "./styles.module.css"
import Search from '../../Components/Search/Search'
import Test from '../../Components/Table/Test'
import Ion from '../../Assets/Images/ion.jpg'
import UploadVideoModal from '../UMUploadVideoModal/UploadVideoModal'
import { userRequest } from '../../Components/RequestMethod'
import { message } from 'antd'

const ViewDetailModal = ({viewDetailData}) => {
   //console.log(viewDetailData)

    const navigate = useNavigate();
    const [userPost ,setUserPost] = useState([]);
    //console.log(userPost)
    const [currentPage, setCurrentPage] = useState(1);
  
    const recordsPerPage = 3;
    const lastIndex = currentPage*recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const userPost1 = userPost.slice(firstIndex,lastIndex);
    const npage = userPost? Math.ceil(userPost.length/recordsPerPage) : 0;

    // const fetchData = async () => {
    //     await userRequest.get("/admin/post/getAllPost?status&search=")
    //       .then((response) => {
    //         const result = response.data.data;
    //         setUserPost(result);
    //       // message.success("data fetched successfully");
    //       })
    //       .catch((err) => {
    //         const errorMessage = err.response?.data?.message || "An error occurred";
    //         message.error(errorMessage);
    //       });
    //   };   

    const fetchSingleUserData = async () => {
          await userRequest.get(`/admin/post/getAllPost?search=&userId=${viewDetailData._id}`)
        //  await userRequest.get('/admin/post/getAllPost?search=&userId=64da251008b8b58990b1dbc0')
          .then((response) => {
            const allPosts = response.data.data;
            
            // const requiredPosts = allPosts.filter(post => post.userId._id === viewDetailData._id);
            // setUserPost(requiredPosts);
             setUserPost(allPosts);

          // message.success("data fetched successfully");
          })
          .catch((err) => {
            const errorMessage = err.response?.data?.message || "An error occurred";
            //console.log(errorMessage)
            message.error(errorMessage);
          });
      };
      //console.log(userPost);

      useEffect(()=>{
        fetchSingleUserData()
    },[]);

    /////////////////////////////////////////////////////////////displaying upload video modal
    const [showUploadVideoModal, setShowUploadVideoModal] = useState(false);
    const [uploadVideoData, setUploadVideoData] = useState();

const uploadVideo=(userData)=>{
  setShowUploadVideoModal(true);
  setUploadVideoData(userData);
}

  return (
    <>
        <div className={styles.user_right}>

   {/*<Search />                       Search as component used here  */}

<div className={styles.header}>
  <div className={styles.header_left}>
    <div className={styles.profPic}>
  <img src={viewDetailData.profilePic} style={{height :'90%', width:'90%',borderRadius:'50%'}}></img>
  </div>
  <div className={styles.macintosh}>
  <p className={styles.macintosh1}>{viewDetailData.fullName}</p>
  <p className={styles.macintosh2}>@{viewDetailData.userName}</p>
  </div>
  </div>

  <div className={styles.header_right}>
    <button onClick={()=>uploadVideo(viewDetailData)}>Upload Videos</button>
  </div>
  </div>


<div className={styles.mainbody}>
<div className={styles.body}>
  
    <p className={styles.userInfoText}>User Info</p>
    <div className={styles.userInfo}>
    <div className={styles.userPhone}>
      <p>Phone Number</p>
      <p className={styles.userDet}>{viewDetailData.phoneNumber}</p>
    </div>
    <div className={styles.userEmail}>
      <p>E-Mail</p>
      <p className={styles.userDet}>{viewDetailData.email}</p>
    </div>
    </div>
    <p className={styles.userVideoText}>Videos</p>

    <div className={styles.userTable}>
       <Test userPost1={ userPost1 } fetchSingleUserData={fetchSingleUserData}/>     {/* Table as component used here  */}
    </div>
    </div>
    {showUploadVideoModal && <UploadVideoModal uploadVideoData={uploadVideoData}
            setShowUploadVideoModal={setShowUploadVideoModal} fetchSingleUserData={fetchSingleUserData}/>}

    {npage > 1 ? (        
    
    <div className={styles.footer}>
     <div className={styles.userBtn}>
      <button className={styles.userPrev} onClick={prePage}>Previous</button>
      <button className={styles.userNext} onClick={nextPage}>Next</button>
     </div>
     <div className={styles.pagination}>{currentPage} of {npage}</div>
     </div>
    ) : null 
    } 

</div>
</div>
    </>
  )
  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1);
    }
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1);
    }
  }
}

export default ViewDetailModal