import React,{ useState,useEffect } from 'react'
import styles from './styles.module.css'
import { message } from 'antd'
import { publicRequest } from '../RequestMethod'

const ChangePasswordModal = ({setShowChangePassword,setShowProfile}) => {

    const [email,setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [emailTouched, setEmailTouched] = useState(false);

    const [newPassword,setNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordTouched, setPasswordTouched] = useState(false);

    const [confirmPassword,setConfirmPassword] = useState('');

    /////////////////////////////////////////////////////////////valdation part
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i ;
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/ ;

    useEffect(() => {
      if (emailTouched) {
        if(!email.trim()){
          setEmailError('Please enter email');
        }
        else if (!emailRegex.test(email)) {
        setEmailError('Invalid email address');
      } else {
        setEmailError('');
      }}
       if (passwordTouched) {
        if(!newPassword.trim()){
          setPasswordError('Please enter password');
        }
        else if (!passwordRegex.test(newPassword)) {
        setPasswordError('Password should be 8char long with 1special char & 1num');
      } else {
        setPasswordError('');
      }}
    }, [email,emailTouched,newPassword,passwordTouched]);

  //////////////////////////////////////////////////////////////////update password API part  
    const newData = JSON.stringify({
        // "email" : localStorage.getItem("email"),
        "email" : email,
        "password" : confirmPassword
      });
    
      const updatePassword = async(e) =>{
        setEmailTouched(true);
        setPasswordTouched(true);
        if(!email.trim()){
          message.error('Please enter email');
        }
        else if(!emailRegex.test(email)){
          message.error('Enter valid email address')
        }
        else if(!newPassword.trim()){
          message.error('Please enter password');
        }
        else if(!passwordRegex.test(newPassword)){
          message.error('Password should be 8char long with 1special char & 1num')
        }
        else if (newPassword !== confirmPassword) {
          message.error("New password and Confirm password do not match");
          return;
        }
        else{
        await publicRequest.put("/admin/auth/forgotPassword",newData)
        .then(()=>{
          message.success("Password updated successfully");
          setShowChangePassword(false);
          setShowProfile(false);
          // navigate('/');
          // localStorage.removeItem("email");
        })
        .catch((err)=>{
          const errorMessage = err.response?.data?.message || "An error occured";
          message.error(errorMessage);
          // localStorage.removeItem("email")
        })
      }
    }

    const handleCancel =()=>{
        setShowChangePassword(false);
        setShowProfile(false);
    }

  return (
    <>
    <div className={styles.modal_container}>
    <p>Change Password</p>
    {/* <label className={styles.modal_label}>New Password</label> */}
         <input type='email' name='' placeholder='Enter email' value={email} 
          onChange={(e)=>setEmail(e.target.value)}
          className={styles.modal_input} onBlur={()=>setEmailTouched(true)}
          ></input>
          {emailError && <div className={styles.error_msg}>{emailError}</div>}
          <input type='password' name='' placeholder='Enter new password' value={newPassword} 
          onChange={(e)=>setNewPassword(e.target.value)}
          className={styles.modal_input} onBlur={()=>setPasswordTouched(true)}
          ></input>
          {passwordError && <div className={styles.error_msg}>{passwordError}</div>}
          {/* <label className={styles.modal_label}>Confirm Password</label> */}
          <input type='password' name='' placeholder='Confirm password' value={confirmPassword} 
          onChange={(e)=>setConfirmPassword(e.target.value)}
          className={styles.modal_input}  
          ></input>
          <div className={styles.modal_btn}>
            <button onClick={()=>handleCancel()}>Cancel</button>
            <button onClick={()=>updatePassword()}>Update</button>
            {/* onClick={()=>updatePassword()} */}
          </div>
    </div>
    </>
  )
}

export default ChangePasswordModal