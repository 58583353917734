import React,{ useState,useEffect } from 'react'
import Search from '../../Components/Search/Search'
import styles from './styles.module.css'
import EditProfileModal from '../../Components/EditProfileModal/EditProfileModal'
import { userRequest } from '../../Components/RequestMethod';
import { message } from 'antd'


const MyProfile = () => {

    const [admin, setAdmin] = useState(false);

    const [showEditProfileModal, setShowEditProfileModal] = useState(false);
    const [editingAdminData, setEditingAdminData] = useState();
    
   //console.log(admin)
   const handleClick = () =>{
        setShowEditProfileModal(true);
        setEditingAdminData(admin);
    }

    const fetchAdmin =async()=>{
      await userRequest.get('/admin/user/getUser')
      .then((response) => {
        const result = response.data;
        setAdmin(result);
        //message.success("data fetched successfully");
      })
      .catch((err) =>{
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      })
    }
    //console.log(admin?.user?.fullName);

    useEffect(()=>{
      fetchAdmin()
    },[]);

  return (
    <>
    <div className={styles.mp_right}>
         <Search />                    {/* Search as component used here  */}

    <div className={styles.header}>
              <div className={styles.header_left}>
              <div className={styles.profPic}>
              <img src={admin?.user?.profilePic} height='40' width='30' alt='missing'></img>
              </div>
              <div className={styles.macintosh}>
              <p className={styles.macintosh1}>{admin?.user?.fullName}</p>
              <p className={styles.macintosh2}>Sales Lead</p>
              </div>
              </div>

              <div className={styles.header_right}>
              <button id={styles.btn_edit} onClick={()=>handleClick()}>Edit Profile</button>
            
              </div>
        </div>

        {showEditProfileModal && <EditProfileModal setShowEditProfileModal={setShowEditProfileModal}
        editingAdminData = {editingAdminData} />}

        <div className={styles.profile_main}>
          <div className={styles.profile_body}> 

          <div className={styles.text}>Personal Info</div>
          <div className={styles.row1}>
          <div>
            <label className={styles.label}>First Name</label>
            <p className={styles.outputtext}>{admin?.user?.fullName}</p>
          </div>
          <div>
            <label className={styles.label}>Last Name</label>
            <p className={styles.outputtext}>{admin?.user?.lastName}</p>
          </div>
          </div>

          <div className={styles.row2}>
          <div>
            <label className={styles.label}>Phone Number</label>
            <p className={styles.outputtext}>{admin?.user?.phoneNumber}</p>
          </div>
          <div>
            <label className={styles.label}>E-Mail</label>
            <p className={styles.outputtext}>{admin?.user?.email}</p>
          </div>
          </div>
          
          <div className={styles.row3}>
            <label className={styles.label}>Home Address</label>
            <p className={styles.outputtext}>{admin?.user?.address}</p>
          </div>
      
      </div>
      </div>

    </div>
    </>
  )
}

export default MyProfile