import React,{useState,useEffect} from 'react'
import styles from './styles.module.css'
import { message } from 'antd'
import { userRequest } from '../../RequestMethod'
import Descriptionbox from '../../AddNotificationDescriptionBox/DescriptionBox'



const EditNotification = ({editNotificationData,setShowEditNotificationModal}) => {
  //console.log(editNotificationData)

  const formatSimpleDate = (dateString) => {
    const dateObj = new Date(dateString);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    //return `${month}/${day}/${year}`;
    return `${year}-${month}-${day}`;
  };
    
    const [date,setDate] = useState(formatSimpleDate(editNotificationData.date));
    const [dateTouched,setDateTouched] = useState(false);
    const [dateError, setDateError] = useState('');

    const [time,setTime] = useState(editNotificationData.time);
    const [timeTouched,setTimeTouched] = useState(false);
    const [timeError, setTimeError] = useState('');

    const [description,setDescription] = useState(editNotificationData.message);
    const [characterLimitExceeded, setCharacterLimitExceeded] = useState(false);

    
    //////////////////////////////////////////////////////////////////Validation part 
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
    const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm)$/i;
    
    useEffect(() => {
      if (dateTouched){
        if(!date.trim()){
          setDateError('Please provide date');
        }
      else if(!dateRegex.test(date)) {
        setDateError('Enter correct date in yyyy-mm-dd format');
      } else {
        setDateError('');
      }
      }
      if (timeTouched){
        if(!time.trim()){
          setTimeError('Please provide time');
        }
      else if(!timeRegex.test(time)) {
        setTimeError('Enter correct time hh:mm am/pm format');
      } else {
        setTimeError('');
      }
      }
    })


    const handleCancel = ()=>{
        setShowEditNotificationModal(false);
    }
    let newData = JSON.stringify({
        "notificationId": editNotificationData._id,
        "date": date,
        "time": time,
        "message": description,
      });

      const updateNotification = async(e) => {
        
        setDateTouched(true);
        setTimeTouched(true);
      
         if(!date.trim()){
          message.error('Please provide date');
        }
        else if(!dateRegex.test(date)){
          message.error('Enter correct date in yyyy-mm-dd format')
        }
        else if(!time.trim()){
          message.error('Please provide time');
        }
        else if(!timeRegex.test(time)){
          message.error('Enter correct time in hh:mm am/pm format')
        }
        else if(characterLimitExceeded){
          message.error('Description characters limit exceeded')
        }
        else {

        await userRequest.post("/admin/notification/createOrUpdate",newData)
        .then(()=>{
            message.success("Notification updated successfully");
            setShowEditNotificationModal(false);
            // fetchData();
            window.location.reload();
        })
        .catch((err) => {
            const errorMessage = err.response?.data?.message || "An error occurred";
            message.error(errorMessage);
        })
      }}

  return (
    <>
    <div className={styles.addUser_right}>
    <div className={styles.header}>
        <p className={styles.text}>Edit notification</p>
        <p className={styles.text_mini}>Edit notification for your activities</p>
    </div>

    <div className={styles.body}>
                <p>Videos</p>                      {/* Videos is hidden here  */}  
                <div className={styles.white_body}>
                <div className={styles.white_body_left}>
                    <label>Time Stamp</label>
                    <input type='text' placeholder='Set Date' className={styles.common_input}
                    value={date} onChange={(e)=>setDate(e.target.value)}
                    onBlur={()=>setDateTouched(true)}></input>
                    {dateError && dateTouched &&(
                  <span className={styles.error_message}>{dateError}</span>
                   )}

                    <input type='text' placeholder='Set Time' className={styles.common_input}
                    value={time} onChange={(e)=>setTime(e.target.value)}
                    onBlur={()=>setTimeTouched(true)}></input>
                   {timeError && timeTouched &&(
                  <span className={styles.error_message}>{timeError}</span>
                   )}
                   
                    <label>Message</label>
                    {/* <textarea
                    className={styles.des_box}
                    value={description}
                    onChange={(e)=>setDescription(e.target.value)}
                    placeholder="Type your message here"
                    rows="4"
                    cols="50"
                    /> */}
                    
                    <Descriptionbox description={description} setDescription={setDescription}
                    characterLimitExceeded={characterLimitExceeded} setCharacterLimitExceeded={setCharacterLimitExceeded} />
                    
                  
                    <div className={styles.btn}>
                        <button className={styles.cancel} onClick={handleCancel}>Cancel</button>
                        <button className={styles.publish} onClick={()=>updateNotification()}>Publish</button>
                    </div>
                </div>
                </div>
            
        </div>
    </div>
    </>
  )
}

export default EditNotification