import React,{useState} from 'react'
import styles from './styles.module.css'
import { useNavigate } from 'react-router'
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal'

const ProfileModal = ({setShowProfile}) => {

  const[showChangePassword,setShowChangePassword] = useState(false);

  const navigate = useNavigate();
    
    const handleChangePassword=()=>{
      setShowChangePassword(true);
      //setShowProfile(false);
      // console.log('password')
    }

    const handleMyProfile=()=>{
      navigate('/myProfile');
      setShowProfile(false);
      window.location.reload();
  }

    const handleNotifications=()=>{
      navigate('/pushNotif');
      setShowProfile(false);
      window.location.reload();
  }

  return (
    <>
    <div className={styles.prof_container}> 
        <p onClick={()=>handleMyProfile()}>My Profile</p>
        <p onClick={()=>handleChangePassword()}>Change Password</p>
        <p onClick={()=>handleNotifications()}>Notifications</p>
        
    </div>
    { showChangePassword && <ChangePasswordModal setShowChangePassword={setShowChangePassword}
    setShowProfile={setShowProfile}/>} 
    </>
  )
}

export default ProfileModal
