import React,{ useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styles from './styles.module.css';
import Search from '../../Components/Search/Search';
import RadioButton from '../../Components/AddNotificationRadioButton/RadioButton';
import RadioButtonSend from '../../Components/AddNotificationRadioButton/SendToRadioButton/RadioButtonSend';
import { message } from 'antd'
import { userRequest } from '../../Components/RequestMethod';
import Descriptionbox from '../../Components/AddNotificationDescriptionBox/DescriptionBox';
import dropDown from '../../Assets/Images/ddgrey.png'
import SelectUser from '../../Components/AddNotificationSelectUserModal/SelectUser';


const AddNotifications = () => {

  const navigate = useNavigate();

    const [title,setTitle] = useState('');
    const [titleTouched,setTitleTouched] = useState(false);
    const [titleError, setTitleError] = useState('');

    const [date,setDate] = useState('');
    const [dateTouched,setDateTouched] = useState(false);
    const [dateError, setDateError] = useState('');

    const [time,setTime] = useState('');
    const [timeTouched,setTimeTouched] = useState(false);
    const [timeError, setTimeError] = useState('');

    const [description,setDescription] = useState('');
    const [characterLimitExceeded, setCharacterLimitExceeded] = useState(false);

    const [selectedValue, setSelectedValue] = useState(null);   //for radio button
    const [sendSelectedValue, setSendSelectedValue] = useState(null);   //for radio button
    const [userId,setUserId] = useState([]);

    //console.log(sendSelectedValue);
    //////////////////////////////////////////////////////////////////////////validation part
    const titleRegex = /^[A-Za-z]{2,50}( [A-Za-z]{2,50}){0,4}$/;
    ///^[A-Za-z]{2,50}( [A-Za-z]{2,50}){0,1}$/
    //const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/; dd-mm-yyyy 
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
    const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm)$/i;

    useEffect(() => {
      if (titleTouched){
        if(!title.trim()){
          setTitleError('Please provide title');
        }
      else if(!titleRegex.test(title)) {
        setTitleError('Enter correct title(with 2-50 char.)');
      } else {
        setTitleError('');
      }
    }
    if (dateTouched){
      if(!date.trim()){
        setDateError('Please provide date');
      }
    else if(!dateRegex.test(date)) {
      setDateError('Enter correct date in yyyy-mm-dd format');
    } else {
      setDateError('');
    }
    }
    if (timeTouched){
      if(!time.trim()){
        setTimeError('Please provide time');
      }
    else if(!timeRegex.test(time)) {
      setTimeError('Enter correct time hh:mm am/pm format');
    } else {
      setTimeError('');
    }
    }
  })
    

    ////////////////////////////////////////////////////////////////////////////////////
    let data = JSON.stringify({
      "notificationId" : false,
      "title" : title,
      "date" : date,
      "time" : time,
      "type" : selectedValue,
      "message" : description,
      "isIndividual" : sendSelectedValue,
      "users" : userId,
    })
    
    const addNotification = async (e) => {
      setTitleTouched(true);
      setDateTouched(true);
      setTimeTouched(true);
      if(!title.trim()){
        message.error('Please provide a title');
      }
      else if(!titleRegex.test(title)){
        message.error('Enter correct title(with 2-50 char.)')
      }
      else if(!date.trim()){
        message.error('Please provide date');
      }
      else if(!dateRegex.test(date)){
        message.error('Enter correct date in yyyy-mm-dd format')
      }
      else if(!time.trim()){
        message.error('Please provide time');
      }
      else if(!timeRegex.test(time)){
        message.error('Enter correct time in hh:mm am/pm format')
      }
      else if(characterLimitExceeded){
        message.error('Description characters limit exceeded')
      }
      else if(!selectedValue){
        message.error('Please select notification type')
      }
      else if(!sendSelectedValue){
        message.error('Please select a Send to option')
      }
      else if (sendSelectedValue === 'true' && userId.length === 0) {
        message.error('Please select User(s)');
      }


      else{
      await userRequest.post("/admin/notification/createOrUpdate", data)
      .then(() => {
        message.success("Notification added successfully");
        navigate('/pushNotif');
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      });
    }};

    //////////////////////////////////////////////////////////////////handling dropdown
    
     const [showSelectUser,setShowSelectUser] = useState(false);

     const sgDropDown = ()=>{  
      // setShowProfile(true);
      setShowSelectUser((prevShowGroup) => !prevShowGroup);
    }

    

  return (
    <>
    <div className={styles.addNotif_right}>
         <Search />              {/* Search as component used here  */}
         <div className={styles.header}>
          <div className={styles.header1}>
          <p>Add Notification</p>
          <text>Add new notifications for your activities</text>
          </div>
          <button className={styles.addNotif} onClick={()=>addNotification()}>+ Add </button>
       </div>

       <div className={styles.body}>
                  {/*   <p>Videos</p>                Videos is hidden here.  crucial part*/}  
                <div className={styles.white_body}>
                  <div className={styles.white_body_left}>
                    <label>Title</label>
                    <input type='text' placeholder='Give title to notification' className={styles.common_input}
                    value={title} onChange={(e)=>setTitle(e.target.value)}
                    onBlur={()=>setTitleTouched(true)}></input>
                    {titleError && titleTouched &&(
                  <span className={styles.error_message}>{titleError}</span>
                   )}
                    <label>Time Stamp</label>
                    <input type='text' placeholder='Set Date' className={styles.common_input}
                    value={date} onChange={(e)=>setDate(e.target.value)}
                    onBlur={()=>setDateTouched(true)}></input>
                    {dateError && dateTouched &&(
                  <span className={styles.error_message}>{dateError}</span>
                   )}
                    <input type='text' placeholder='Set Time' className={styles.common_input}
                    value={time} onChange={(e)=>setTime(e.target.value)}
                    onBlur={()=>setTimeTouched(true)}></input>
                    {timeError && timeTouched &&(
                  <span className={styles.error_message}>{timeError}</span>
                   )}
                    
                    <label>Message</label>
                    {/* <textarea
                    className={styles.des_box}
                    value={description}
                    onChange={(e)=>setDescription(e.target.value)}
                    placeholder="Type your message here"
                    rows="6"
                    cols="50"
                    /> */}
                    <Descriptionbox description={description} setDescription={setDescription}
                    characterLimitExceeded={characterLimitExceeded} setCharacterLimitExceeded={setCharacterLimitExceeded}/>
                    <label>Type</label>
                    <RadioButton selectedValue={selectedValue} setSelectedValue={setSelectedValue} /> {/* Radiobutton as component used here  */}
                  </div>


                  <div className={styles.white_body_right}>
                    <label>Send to</label>
                    <RadioButtonSend sendSelectedValue={sendSelectedValue} setSendSelectedValue={setSendSelectedValue} />
                    <div className={styles.select_user}>
                      <p>Select User</p>
                      <img src={dropDown} id={styles.pic_dropdown} onClick={()=>sgDropDown()}></img>
                    </div>
                     { showSelectUser && <SelectUser userId={userId} setUserId={setUserId}/>}    {/*  SelectGroup as modal used here */}

                    {/* <div className={styles.select_user}>
                      <p>Select User</p>
                      <img src={dropDown} id={styles.pic_dropdown} ></img>
                    </div> */}
                  </div>
                     

                </div>
            </div>

    </div>
    </>
  )
}

export default AddNotifications