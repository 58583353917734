import React,{ useState,useEffect } from 'react'
import styles from './styles.module.css'
import Search from '../../Components/Search/Search'
// import { DatePicker } from "antd";
import PushNotifTest from '../../Components/Table/PushNotifTest'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { userRequest } from '../../Components/RequestMethod'
import AllNotifTest from '../../Components/Table/AllNotifTest';
import debounce from 'lodash.debounce';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AiTwotoneCalendar } from 'react-icons/ai'; 


const NotificationManagement = () => {
  ///////////////////////////////////////////////////////////////////////////date picker
const today = new Date();
const [startDate, setStartDate] = useState();
const [endDate, setEndDate] = useState(today);
// const [endDate, setEndDate] = useState();

const handleStartDateChange = (date) => {
  setStartDate(date);
  if (date > endDate) {
    setEndDate(date);
  }
};

const handleEndDateChange = (date) => {
  setEndDate(date);
};
////////////////////////////////////////////////////////////////////////////

  const [notifData,setNotifData] = useState([]);
  //console.log(notifData);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [selectedNButton, setSelectedNButton] = useState('allNotif');
  const navigate = useNavigate();

  const fetchNotifications = async (searchQuery) => {
    const statusQueryParam = currentStatus ? `${currentStatus}` : '';
    const statusStartDate = startDate ? `${startDate}` : '';
    const statusEndDate = endDate ? `${endDate}` : '';
    
    // console.log(statusQueryParam);
    //await userRequest.get(`/admin/notification/getAllNotifications?type=${statusQueryParam}&search=${searchQuery}`)
    await userRequest.get(`/admin/notification/getAllNotifications?startDate=${statusStartDate}&endDate=${statusEndDate}
    &type=${statusQueryParam}&search=${searchQuery}`)
    // await userRequest.get(`/admin/notification/getAllNotifications?search=${searchQuery}&type=${statusQueryParam}
    // &startDate=${statusStartDate}&endDate=${statusEndDate}`)
     .then((response) => {
       const result = response.data.data;
       setNotifData(result);
     // message.success("data fetched successfully");
     })
     .catch((err) => {
       const errorMessage = err.response?.data?.message || "An error occurred";
       message.error(errorMessage);
     });
 };

 useEffect(()=>{
    fetchNotifications(" ");
  },[currentStatus,endDate]);

const handleAllNotif = () => {
  setCurrentStatus('');
  setSelectedNButton('allNotif');
  //console.log('in requested');
  setCurrentPage(1);
};

const handleCustomNotif = () => {
  setCurrentStatus('Custom');
  setSelectedNButton('customNotif');
  //console.log('in accepted');
  setCurrentPage(1);
};

const handlePushNotif = () => {
  setCurrentStatus('Push');
  setSelectedNButton('pushNotif');
  //console.log('in rejected');
  setCurrentPage(1);
};
  ///////////////////////////////////////////////////////applying search filter & debounce

  const [searchText, setSearchText] = useState('');

  const handleSearch = (e) => {
      setSearchText(e.target.value);
      // fetchData(e.target.value);        before debounce 
      debouncedFetchData(searchText);  

    if(e.target.value === ''){
    fetchNotifications();
    // window.location.reload();
    // console.log('ho gya')
    }
  };
  const debouncedFetchData = debounce(fetchNotifications, 2000);
   ///////////////////////////////////////////////////////////////applying pagination

 const [currentPage, setCurrentPage] = useState(1);

 const recordsPerPage = 4;
 const lastIndex = currentPage*recordsPerPage;
 const firstIndex = lastIndex - recordsPerPage;
 const notifData1 = notifData.slice(firstIndex, lastIndex);
 const npage = notifData? Math.ceil(notifData.length/recordsPerPage): 0;
//console.log(notifData1)

              
///////////////////////////////////////////////////////////////////////////

  return (
    <>
    <div className={styles.Notif_right}>
    <Search searchText={searchText} handleSearch={handleSearch} />  {/* Search as component used here */}
    <div className={styles.header}>
            <div className={styles.header_top}>
              <div className={styles.header_left}>
              <p>Notification Management </p>
              </div>

              <div className={styles.header_right}>
                <div className={styles.header_right_first}>
                <p style={{fontSize:"0.8rem",fontWeight:"500"}}>From</p>
                {/* <DatePicker style={{width:"75%",height:"50%", borderRadius:"1.8vw",marginTop:"-0.75rem"}}/> */}
                <div className={styles.custom_datepicker}>
                   <AiTwotoneCalendar className={styles.date_picker_icon} />
                   <DatePicker
                   selected={startDate}
                   onChange={handleStartDateChange}
                  selectsStart
                  maxDate={today} // Set the maximum date to today
                   dateFormat='MMM d, yyyy'
                  //dateFormat="yyyy-MM-dd"
                  className={styles.customDatePickerInput} 
                  />
                 </div>
                </div>

                <div className={styles.header_right_second}>
                <p style={{fontSize:"0.8rem",fontWeight:"500"}}>To</p>
                {/* <DatePicker style={{width:"75%",height:"50%", borderRadius:"1.8vw",marginTop:"-0.75rem"}}/> */}
                <div className={styles.custom_datepicker}>
                   <AiTwotoneCalendar className={styles.date_picker_icon} />
                <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                minDate={startDate} // Set the minimum date to the selected start date
                //maxDate={today}  Set the maximum date to today
                maxDate={new Date(8640000000000000)} // A very distant future date
                dateFormat='MMM d, yyyy'
                // dateFormat="yyyy-MM-dd"
                className={styles.customDatePickerInput}
                />
                </div>
                </div>

                <button className={styles.addNotif} onClick={()=>navigate('/addNotif')}>+ Add Notification</button>
              </div>
            </div>

              <div className={styles.header_bottom}>
                <button className={selectedNButton === 'allNotif' ? styles.req_notif : ''}
                onClick={handleAllNotif}>All Notifications</button>
                <button className={selectedNButton === 'customNotif' ? styles.req_notif : ''}
                onClick={handleCustomNotif}>Custom</button>
                <button className={selectedNButton === 'pushNotif' ? styles.req_notif : ''}
                onClick={handlePushNotif}>Push Notifications</button>
              </div>

            </div>


            <div className={styles.body}>
                     <p>Videos</p>              {/*  Videos is hidden here.  crucial part*/}  
                <div className={styles.white_body}>
                    <div className={styles.table}>
                      { selectedNButton === 'pushNotif' ? 
                      <PushNotifTest notifData1={notifData1}/>       
                      : <AllNotifTest notifData1={notifData1}/>  }      {/* Table as component used here */}

                    </div>   
                </div>

                {npage > 1 ? (
               
               <div className={styles.footer}>
                   <div className={styles.userBtn}>
                     <button className={styles.userPrev} onClick={prePage}>Previous</button>
                     <button className={styles.userNext} onClick={nextPage}>Next</button>
                   </div>
   
                    <div className={styles.pagination}>{currentPage} of {npage}</div>
               </div> 
                ) : null
                } 
           
            </div>
    </div>
    </>
  )
  function prePage(){
    if(currentPage !== 1){
        setCurrentPage(currentPage - 1);
    }
  }

  function nextPage(){
    if(currentPage !== npage){
        setCurrentPage(currentPage + 1);
    }
  }
}

export default NotificationManagement